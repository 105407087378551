import * as url from "../url_helper";
import { request } from "../api_helper";

// get Notification
//url/{id}
export const showNotification = (notificationId) =>
  request({ url: `${url.NOTIFICATIONS}/${notificationId}`, method: "GET" });

// update Notification
//url/{id}
export const updateNotification = (data) =>
  request({ url: `${url.NOTIFICATIONS}/${data.id}`, method: "PUT", data });

// patch update Notification (make them read or unread)
//url/{id}
export const updateNotificationsRead = (data) =>
  request({ url: url.NOTIFICATIONSLOGS, method: "PATCH", data });

// get all Notifications with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllNotifications = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.NOTIFICATIONS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  });
