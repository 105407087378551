export const messages = {
  // general
  requiredFields: "Please Fill All Required Data",
  selectProductPlans:
    "To Generate A Policy, Please Include Product And Benefit Plans.",
  familyWithProductPlans:
    "To Compute, Please Include Product And Benefit Plans.",

  backToTop: "Back to top",
  blackListedSuccess: "Item Blacklisted Successfully",
  whiteListedSuccess: "Item WhiteListed Successfully",
  // unsaved tab changes
  unsavedTabChanges:
    "You have unsaved changes , are you sure you want to leave this page?",
  // reject policy Modal
  rejectPolicyTitle: "Change Policy Status",
  rejectPolicyMessage: "Are you sure you want to change policy status?",
  selectProduct: "Please Select Product First",
  selectMember: "Please Select Member First",
  selectPlans: "Please Select Plans First",
  computeFirst: "Please Compute First",

  // Medical Declaration
  // yesAnswer: "Be cautious One of the medical questions gets a 'YES' response !",
  yesAnswer:
    "Please confirm the accuracy of the answers to the medical questions",
  above55: "This member is not eligible for LG due to the age constraint",
  medicalDeclaration: "Please fill out medical declaration",
  BMIExceeds: "The BMI exceeds the range for obesity",
  duplicatedMemebers:
    "Be cautious There is a memeber with the same name and birth date !",
  // endorsement
  saveEndorsement: "Are you sure you want to save this endorsement?",
  // renew policy
  renewPolicy: "Are you sure you want to Renew This Policy?",
  changePlans: "Are you sure you want to change those plans?",
  cobabyDOBError: "Date of Birth Can’t be More than Two Months",
}
