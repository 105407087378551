import {
  GET_ACTIONS,
  GET_ACTIONS_SUCCESS,
  GET_ACTIONS_FAIL,
  GET_SUBMODULES,
  GET_SUBMODULES_SUCCESS,
  GET_SUBMODULES_FAIL,
  GET_SECTION,
  GET_SECTION_SUCCESS,
  GET_SECTION_FAIL,
} from "./actionTypes"

// Get Actions Allowed on Each Module (READ)

export const getActions = moduleId => {
  return {
    type: GET_ACTIONS,
    payload: moduleId,
  }
}
export const getActionsSuccess = moduleId => {
  return {
    type: GET_ACTIONS_SUCCESS,
    payload: moduleId,
  }
}
export const getActionsFail = error => {
  return {
    type: GET_ACTIONS_FAIL,
    payload: error,
  }
}

// Get SubModules and Input Fields Allowed on Each Module (READ)

export const getSubmodules = moduleId => {
  return {
    type: GET_SUBMODULES,
    payload: moduleId,
  }
}
export const getSubmodulesSuccess = moduleId => {
  return {
    type: GET_SUBMODULES_SUCCESS,
    payload: moduleId,
  }
}
export const getSubmodulesFail = error => {
  return {
    type: GET_SUBMODULES_FAIL,
    payload: error,
  }
}

// Get Sections and Input Fields Allowed on Submodule in submodule

export const getSection = moduleId => {
  return {
    type: GET_SECTION,
    payload: moduleId,
  }
}
export const getSectionSuccess = moduleId => {
  return {
    type: GET_SECTION_SUCCESS,
    payload: moduleId,
  }
}
export const getSectionFail = error => {
  return {
    type: GET_SECTION_FAIL,
    payload: error,
  }
}
