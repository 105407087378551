import { Skeleton } from "antd"
import React from "react"

const DropDownSkeleton = () => {
  return (
    <Skeleton
      active
      // round={true}
      title={false}
      style={{ marginTop: "10px" }}
      paragraph={{
        rows: 1,
        width: "100%",
      }}
    />
  )
}

export default DropDownSkeleton
