import { addFile } from "helpers/General/files_helper"
import { toast } from "react-hot-toast"
import { NotificationMessage } from "utils/NotificationMessage"
import { apiErrorrHandler } from "utils/apiErrorHandler"

export const UploadFiles = async (selectedFiles, description) => {
  let filesIds = []
  // old files (if it has an ID that means it’S already uploaded in DB)
  const oldFilesIds = selectedFiles
    .filter((item) => item.id)
    .map((item) => item.id)

  // new files (files without IDs (new files that the user has just dropped))
  const newFiles = selectedFiles.filter((item) => !item.id)

  try {
    if (newFiles.length > 0) {
      const formData = new FormData()

      newFiles.map((file, index) => formData.append(`paths[${index}]`, file))
      formData.append(
        "description",
        description ? description : `Health Care Contract File`
      )
      const filesRes = await addFile(formData)
      toast.success("Files Uploaded Successfully")
      const getFileIds = filesRes?.item?.map((file) => file.id)
      filesIds = getFileIds
    }
  } catch (error) {
    const errorMessage = apiErrorrHandler(error)
    NotificationMessage("Error", errorMessage)
    return
  }

  return [...filesIds, ...oldFilesIds]
}
