// import React from "react"
import { notification } from "antd"

export const NotificationMessage = (type, description) => {
  let message
  switch (description.toLowerCase()) {
    case "add":
      message = "Item Added Successfully"
      break
    case "activate":
      message = "Item Activated Successfully"
      break
    case "deactivate":
      message = "Item Deactivated Successfully"
      break

    case "update":
      message = "Item Updated Successfully"
      break
    case "delete":
      message = "Item Deleted Successfully"
      break
    case "copy":
      message = "Item Cloned Successfully"
      break
    case "whitelisted":
      message = "Item WhiteListed Successfully"
      break
    case "blacklisted":
      message = "Item Blacklisted Successfully"
      break

    default:
      message = description
  }
  return type.toLowerCase() === "success"
    ? notification.success({
        description: message,
        message: "Success",
      })
    : notification.error({
        description: message,
        message: "Error",
      })
}

// export default NotificationMessage
