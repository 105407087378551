// All ACTIONS
export const GET_ACTIONS = "ACTIONS"
export const GET_ACTIONS_SUCCESS = "ACTIONS_SUCCESS"
export const GET_ACTIONS_FAIL = "ACTIONS_FAIL"

// All SUBMODULES
export const GET_SUBMODULES = "GET_SUBMODULES"
export const GET_SUBMODULES_SUCCESS = "GET_SUBMODULES_SUCCESS"
export const GET_SUBMODULES_FAIL = "GET_SUBMODULES_FAIL"

// Sections
export const GET_SECTION = "GET_SECTION"
export const GET_SECTION_SUCCESS = "GET_SECTION_SUCCESS"
export const GET_SECTION_FAIL = "GET_SECTION_FAIL"
