import { call, put, takeEvery } from "redux-saga/effects"

// User Redux States
import { GET_MODULES } from "./actionTypes"
import { getModulesSuccess, getModulesFail } from "./actions"

// import helpers functions

import { getAllModules } from "../../../helpers/Permissions/permissions_helper"

function* onGetModules() {
  try {
    const response = yield call(getAllModules)
    const { item: modules } = response
    // console.log("response", modules)
    console.log("response", response)
    // const {item:modules} = response;
    yield put(getModulesSuccess(modules))
    // yield put(getModulesSuccess(modules.slice(0, 1)))
  } catch (error) {
    console.log("error", error)
    yield put(getModulesFail(error))
  }
}

function* sidebarSaga() {
  yield takeEvery(GET_MODULES, onGetModules)
}

export default sidebarSaga
