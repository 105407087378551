import {
  GET_MODULES,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
} from "./actionTypes"

// Get Modules/Permissions (READ)

export const getModules = () => ({
  type: GET_MODULES,
})

export const getModulesSuccess = modules => ({
  type: GET_MODULES_SUCCESS,
  payload: modules,
})

export const getModulesFail = error => ({
  type: GET_MODULES_FAIL,
  payload: error,
})
