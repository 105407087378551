import React from "react"

const Preloader = () => {
  return (
    <div id="preloader" className="bg-white bg-opacity-75">
      <div id="status">
        <div className="spinner-chase">
          {Array.from({ length: 6 }).map((_, i) => (
            <div className="chase-dot" key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Preloader
