import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

import { updateProfile } from "helpers/AuthType/auth_helper"
import { apiErrorrHandler } from "utils"
import { loginSuccess } from "../login/actions"

function* editProfile({ payload: { user, history, setSubmitting } }) {
  // const formData = new FormData()
  // formData.set("first_name", user.first_name)
  // formData.set("last_name", user.last_name)
  // formData.set("phone", user.phone)
  // formData.append("photo", user.photo)
  console.log(user.photo)
  try {
    // const response = yield call(updateProfile, {
    //   first_name: user.first_name,
    //   last_name: user.last_name,
    //   phone: user.phone,
    //   // photo: user.photo,
    //   // photo: formData,
    //   formData,
    // })
    const formData = new FormData()
    formData.set("first_name", user.first_name)
    formData.set("last_name", user.last_name)
    formData.set("phone", user.phone)
    formData.append("photo", user.photo)
    const response = yield call(updateProfile, formData)
    yield put(profileSuccess("Profile Updated Successfully"))

    const localStorageUser = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : null

    if (localStorageUser) {
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...localStorageUser,
          phone: response.item.phone,
        })
      )
    }
    // yield put(loginSuccess(response.item))

    // history.push("/")
  } catch (error) {
    const errorMessage = apiErrorrHandler(error)
    console.log(errorMessage)
    yield put(profileError(errorMessage))
  } finally {
    setSubmitting(false)
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
