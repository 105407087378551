/*
This is Hook is used to Extract the whether show black list modal or white list modal
*/

import { stakeholderStatus } from "common/data"
import React, { useEffect, useState } from "react"

const useBlackWhiteList = (row) => {
  const [blackList, setBlackList] = useState(false)
  const [whiteList, setWhiteList] = useState(false)

  useEffect(() => {
    if (
      !row?.status ||
      row?.status?.slug === stakeholderStatus.whitelisted.slug
    ) {
      setBlackList(true)
    }

    // if the item is black listed open white list modal
    if (
      row?.status &&
      row?.status?.slug === stakeholderStatus.blacklisted.slug
    ) {
      setWhiteList(true)
    }
  }, [JSON.stringify(row)])

  return { blackList, whiteList }
}

export default useBlackWhiteList
