import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
// UI Components
import { FormModal, ModalSpinner } from "components/TP/Common/Forms"
import { SearchSelect } from "components/TP/Common/Forms"

// helper functions
import { showPolicy } from "helpers/Production/Policies/policies_helper"
//utils functions
import { apiErrorrHandler, NotificationMessage, objectToValue } from "utils"
import {
  buttonsTitles,
  memberDependencies,
  policyTypes,
  staticFieldLabels,
} from "common/data"
import { stakeholderPaths } from "common/data/routePaths"

const SelectContractMemberModal = ({ isModal, setIsModal, policyId }) => {
  // local states
  const [isLoading, setIsLoading] = useState(false)
  //   controll selected option from the dropdown
  const [selectedMember, setSelectedMember] = useState(null)
  //   store dropdown options
  const [memberOptions, setMemberOptions] = useState([])

  const getPolicy = async (policyId) => {
    setIsLoading(true)
    try {
      const response = await showPolicy(policyId)
      //   format the principle members to put them in the dorpdowns
      const policyHolders = response.item.product_co_participation_beneficiaries
        .filter(
          (item) => item.dependency?.slug === memberDependencies.principle.slug
        )
        .map((item) => ({
          ...item,
          value: item?.id,
          label: item?.name?.toUpperCase(),
        }))
      setMemberOptions(policyHolders)
      if (response.item?.type?.id === policyTypes.individual.id) {
        setSelectedMember(policyHolders[0])
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (policyId) {
      getPolicy(policyId)
    }
  }, [policyId])

  //   after click print contract button
  //redirect to print contract page
  const redirectToPrintPage = () => {
    if (!policyId || !selectedMember) return
    setIsModal(false)
    window.open(
      `${stakeholderPaths.beneficiaryList}/print/${objectToValue(
        selectedMember,
        "id"
      )}/${policyId}`
    )
  }
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
      }}
      onSaveClick={() => redirectToPrintPage()}
      modalTitle={buttonsTitles.printContract}
      isSaving={!selectedMember || isLoading ? true : false}
      saveButtonTitle={buttonsTitles.printContract}
    >
      {isLoading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          <SearchSelect
            id="2000"
            label={staticFieldLabels.memberName}
            placeholder="Select"
            value={selectedMember?.id}
            options={memberOptions}
            customOnChange={(selectedValue) =>
              setSelectedMember(selectedValue ? selectedValue.id : null)
            }
            onBlur={() => {}}
          />
        </form>
      )}
    </FormModal>
  )
}

export default withTranslation()(SelectContractMemberModal)
