import React, { Fragment, Suspense, useEffect, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
// UI Components
import {
  FormModal,
  SearchTree,
  ModalSpinner,
  DatePickerInput,
  SearchSelect,
} from "../../Common/Forms"
import { Alert, Row } from "reactstrap"

// redux hooks
import { useDispatch, useSelector } from "react-redux"
//utils functions
import {
  apiErrorrHandler,
  isInputInvalid,
  NotificationMessage,
  fieldFinder,
  convertNullToString,
  generateFieldValidation,
  formatedTodayData,
} from "utils"
import PopUpFormTitle from "components/TP/Common/General/PopUpFormTitle"
import { getSubmodules } from "store/actions"
import {
  beneficaryDetailsModuleId,
  policyCoverageModuleId,
  policyFirstTabFirstSectionId,
  policyFirstTabId,
  policyFirstTabThirdSectionId,
  policyModuleId,
} from "utils/modulesIds"
import {
  activatePolicy,
  showPolicy,
  updatePolicy,
} from "helpers/Production/Policies/policies_helper"
import { showPredefined } from "helpers/Settings/predefineds/predefineds_herlper"
import { showProduct as getProductParticipation } from "helpers/Production/Products/co_particapation"
import { getAllProducts } from "helpers/Production/Products/products_helper"

import { getAllUsers } from "helpers/Settings/Users/users_helper"
import { getAllSubModules } from "helpers/Permissions/permissions_helper"

import moment from "moment"
import MultiSkeleton from "../MultiSkeleton"
import { APIDateFormat } from "constants/TPA"
import { buttonsTitles, policyStatus } from "common/data"
import { Link } from "react-router-dom"
import { stakeholderPaths } from "common/data/routePaths"
import { useParams } from "react-router-dom"
import {
  showBeneficary,
  updateBeneficary,
} from "helpers/Production/Policies/beneficaries_helper"
const PolicyIssueingModal = ({
  isModal,
  setIsModal,
  refetchList,
  recordId,
  inPolicyForm,
  isBeneficiaryForm,
  t,
}) => {
  const dispatch = useDispatch()

  const { beneficiaryId } = useParams()

  // local states
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [policy, setPolicy] = useState(null)
  const [beneficiary, setBeneficiary] = useState(null)
  const [inputs, setInputs] = useState([])

  // policy permission level 3
  const { fields, policyCoverageFields, loading } = useSelector((state) => ({
    // first section inputs
    fields: state?.Module?.subModules
      ?.find((item) => item.id === policyFirstTabId)
      ?.sub_modules?.find((item) => item.id === policyFirstTabFirstSectionId)
      ?.fields,
    policyCoverageFields: state?.Module?.subModules
      ?.find((item) => item.id === beneficaryDetailsModuleId)
      ?.sub_modules?.find((item) => item.id === policyCoverageModuleId)?.fields,
    loading: state.Module.loading,
  }))

  // policy Permission Level 3
  // if the component comming from policy form
  // do not call permission level 3
  useEffect(() => {
    if (!inPolicyForm && !isBeneficiaryForm) {
      dispatch(getSubmodules(policyModuleId))
    } else return
  }, [getSubmodules, inPolicyForm])

  // update Beneficiary in DB
  const handleUpdateBeneficiary = async (values, setSubmitting) => {
    try {
      const res = await updateBeneficary({
        id: beneficiaryId,
        ...beneficiary,
        beneficiary_products: values.beneficiary_products.map((item) => {
          return {
            product_co_participation_id: item,
          }
        }),
      })

      NotificationMessage("success", "update")
      refetchList()
      setError("")
      setIsModal(null)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }

  // update Policy in the DB

  const handleUpdatePolicy = async (values, setSubmitting, resetForm) => {
    try {
      await updatePolicy({
        ...policy,
        ...values,
        status_id: policyStatus.active,
      })
      await activatePolicy(policy?.id)
      if (beneficiaryId && isBeneficiaryForm) {
        handleUpdateBeneficiary(values, setSubmitting)
      } else {
        NotificationMessage("success", "update")
        resetForm()
        refetchList()
        setError("")
        setIsModal(null)
      }
    } catch (error) {
      console.log(error)
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      stop_date: policy ? policy.stop_date : "",
      start_date: policy ? policy.start_date : "",
      date: policy && policy.date ? policy.date : formatedTodayData(),
      expiry_time_id: policy && policy.expiry_time ? policy.expiry_time.id : "",
      payer_id: policy && policy.payer ? policy.payer.id : "",
      currency_id: policy && policy.currency ? policy.currency.id : "",
      type_id: policy && policy.type ? policy.type.id : "",
      broker_id: policy && policy.broker ? policy.broker.id : "",

      beneficiary_products:
        beneficiary && beneficiary.co_participations
          ? beneficiary.co_participations.map((item) => item.id)
          : [],
      product_id:
        beneficiary && beneficiary.policy
          ? beneficiary?.policy?.product?.id
          : "",
    },

    validationSchema: Yup.object({
      expiry_time_id: generateFieldValidation(inputs, 780, "dropdown"),
      stop_date: generateFieldValidation(inputs, 225, "date"),
      start_date: generateFieldValidation(inputs, 226, "date"),
      date: generateFieldValidation(inputs, 227, "date"),
      // currency_id: generateFieldValidation(inputs, 230, "dropdown"),
      // payer_id: generateFieldValidation(inputs, 223, "dropdown"),
      // type_id: generateFieldValidation(thirdInputs, 231, "dropdown"),
      broker_id: generateFieldValidation(
        inputs,
        782,
        "dropdown",
        "",
        isBeneficiaryForm ? false : true
      ),

      beneficiary_products: generateFieldValidation(
        policyCoverageFields,
        298,
        "array"
      ),
      product_id: generateFieldValidation(
        policyCoverageFields,
        295,
        "dropdown"
      ),
    }),

    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleUpdatePolicy(values, setSubmitting, resetForm)
    },
  })

  // Show Policy
  const getData = async (policyId) => {
    try {
      setIsLoading(true)
      const response = await showPolicy(policyId)
      setPolicy(convertNullToString(response.item))
      setIsLoading(false)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
      setIsLoading(false)
    }
  }

  const getBeneficiary = async (id) => {
    setIsLoading(true)
    try {
      const response = await showBeneficary(id)
      setBeneficiary(response.item)
      setIsLoading(false)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (recordId) {
      getData(recordId)
    }
  }, [recordId])

  useEffect(() => {
    if (isBeneficiaryForm && beneficiaryId) {
      getBeneficiary(beneficiaryId)
    } else return
  }, [isBeneficiaryForm, beneficiaryId])

  const getPolicyLevel3 = async () => {
    try {
      setIsLoading(true)
      const res = await getAllSubModules(policyFirstTabFirstSectionId)
      setInputs(res.item?.fields)
      setIsLoading(false)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isBeneficiaryForm) {
      getPolicyLevel3()
    } else {
      setInputs(fields)
    }
  }, [isBeneficiaryForm])
  return (
    <FormModal
      show={isModal}
      onCloseClick={() => {
        setIsModal(false)
        validation.resetForm()
        setError("")
      }}
      onSaveClick={validation.handleSubmit}
      // modalTitle={t("Add Region")}
      modalTitle={<PopUpFormTitle formType="update" />}
      isSaving={validation.isSubmitting}
    >
      {loading || isLoading || !policy ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* API Error Message For Region Module */}

          {error ? <Alert color="danger">{error}</Alert> : null}
          {inputs && inputs.length > 0 && (
            <Fragment>
              {isBeneficiaryForm ? (
                <Fragment>
                  <div className="d-flex justify-content-end">
                    <Link
                      to={`${stakeholderPaths.broker}/add`}
                      target="_blank"
                      className="btn btn-secondary justify-content-end"
                    >
                      {buttonsTitles.addBroker}
                    </Link>
                  </div>

                  {/* policy coverage inputs */}
                  <SearchSelect
                    id="295"
                    name="product_id"
                    inputField={fieldFinder(policyCoverageFields, 295)}
                    value={validation.values.product_id}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    getOptionsData={getAllProducts}
                    generateDisabledOptions={(option) => option.is_active === 0}
                    mappingLabel="name"
                    mappingValue="id"
                    invalid={isInputInvalid(validation, "product_id")}
                    error={validation.errors.product_id}
                    wrapperClassNames="mb-3"
                    // readOnly={beneficiaryId && is_active === 1 ? true : false}
                  />
                  <Suspense fallback={<MultiSkeleton length={1} />}>
                    {/* Benfits Select Drop down */}
                    <SearchTree
                      id="298"
                      name="beneficiary_products"
                      inputField={fieldFinder(policyCoverageFields, 298)}
                      value={validation.values.beneficiary_products || []}
                      onChange={validation.setFieldValue}
                      onBlur={validation.setFieldTouched}
                      fieldNames={{
                        children: "null",
                        label: "name",
                        value: "id",
                      }}
                      getTreeData={
                        validation.values.product_id
                          ? getProductParticipation
                          : () => {}
                      }
                      recordId={validation.values.product_id}
                      showParent={true}
                      invalid={isInputInvalid(
                        validation,
                        "beneficiary_products"
                      )}
                      error={
                        isInputInvalid(validation, "beneficiary_products") &&
                        validation.errors.beneficiary_products
                      }
                      wrapperClassNames="mb-3"
                      multiple={true}
                      // readOnly={
                      //   (beneficiaryId && is_active === 1) || isChild
                      //     ? true
                      //     : false
                      // }
                    />
                  </Suspense>
                  <SearchSelect
                    id="782"
                    name="broker_id"
                    labelClass="reload-button"
                    inputField={fieldFinder(inputs, 782)}
                    value={validation.values.broker_id}
                    onChange={validation.setFieldValue}
                    onBlur={validation.setFieldTouched}
                    getOptionsData={getAllUsers}
                    urlAddOns={{
                      is_commission_based: 1,
                    }}
                    mappingLabel="first_name"
                    generateCustomLabels={(item) =>
                      `${item.first_name} ${item.last_name}`
                    }
                    mappingValue="id"
                    invalid={isInputInvalid(validation, "broker_id")}
                    error={validation.errors.broker_id}
                    generateDisabledOptions={(option) => option.is_active === 0}
                    wrapperClassNames="mb-3"
                  />
                </Fragment>
              ) : null}
              {/* Issueing Date Input  */}
              <DatePickerInput
                id="227"
                name="date"
                type="text"
                inputField={fieldFinder(inputs, 227)}
                value={validation.values.date}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                invalid={isInputInvalid(validation, "date")}
                error={validation.errors.date}
                wrapperClassNames="mb-3"
                disableFutureDates={true}
                pageType="editable"
              />
              {/* Start Date Input  */}
              <DatePickerInput
                id="226"
                name="start_date"
                type="text"
                inputField={fieldFinder(inputs, 226)}
                value={validation.values.start_date}
                // When change the start date
                // put its value in formik state
                // and add one year to this value and put
                // it in the stop date (or expiry date) formik state
                onChange={(date, dateStr) => {
                  validation.setFieldValue("start_date", dateStr)
                  if (dateStr) {
                    validation.setFieldValue(
                      "stop_date",
                      moment(dateStr).add(1, "year").format(APIDateFormat)
                    )
                  }
                }}
                onBlur={validation.setFieldTouched}
                invalid={isInputInvalid(validation, "start_date")}
                error={validation.errors.start_date}
                wrapperClassNames="mb-3"
                removeDisable={true}
                pageType="editable"
              />

              {/* stop Date Input  */}
              <DatePickerInput
                id="225"
                name="stop_date"
                type="text"
                inputField={fieldFinder(inputs, 225)}
                value={validation.values.stop_date}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                invalid={isInputInvalid(validation, "stop_date")}
                error={validation.errors.stop_date}
                wrapperClassNames="mb-3"
                removeDisable={true}
                pageType="editable"
              />
              {/* Expiry time select dropdown */}
              <Suspense fallback={<MultiSkeleton length={1} />}>
                <SearchTree
                  id="780"
                  name="expiry_time_id"
                  inputField={fieldFinder(inputs, 780)}
                  value={validation.values.expiry_time_id || null}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  fieldNames={{
                    children: "children",
                    label: "name",
                    value: "id",
                  }}
                  getTreeData={showPredefined}
                  recordId={fieldFinder(inputs, 780)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "expiry_time_id")}
                  error={validation.errors.expiry_time_id}
                  wrapperClassNames="mb-3"
                />
              </Suspense>
            </Fragment>
          )}
        </form>
      )}
    </FormModal>
  )
}

export default withTranslation()(PolicyIssueingModal)
