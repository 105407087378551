import { messages } from "common/data"
import React, { Fragment } from "react"

const ScrollToTopButton = ({ scrollTop }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  return (
    <Fragment>
      <button
        className={scrollTop ? "scrollBtn show" : "scrollBtn"}
        onClick={() => scrollToTop()}
        data-bs-toggle="tooltip"
        title={messages.backToTop}
      >
        <i className="mdi mdi-chevron-up"></i>
      </button>
    </Fragment>
  )
}

export default ScrollToTopButton
