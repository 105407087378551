import React, { Fragment, useEffect } from "react"
import { useSelector } from "react-redux"
import { CompanyName } from "constants/TPA"

import Breadcrumbs from "components/Common/Breadcrumb"
import { Skeleton } from "antd"
import { usePageType } from "hooks"
import { compact } from "lodash"

const PageBreadCrumbAndTitle = ({
  title,
  // pageType,
  titleAddOn,
  prefix,
  children,
  customBackLink,
  hideBackButton,
  data,
  indexName,
  customTitle,
}) => {
  const { moduleName, loading } = useSelector((state) => ({
    moduleName: state.Module.name,
    loading: state.Module.loading,
  }))

  const { isAddPage, isViewPage, isEditPage, isPrintPage } = usePageType()

  let newPageType

  if (isAddPage) {
    newPageType = " - Add Form"
  } else if (isEditPage) {
    newPageType = " - Edit Form"
  } else if (isViewPage) {
    newPageType = " - View Form"
  } else if (isPrintPage) {
    newPageType = " - Print"
  } else {
    newPageType = ""
  }

  // without company name

  useEffect(() => {
    if (data && indexName) {
      //split by | and remove all { or }
      const splitted = indexName
        .split("|")
        .map((item) => item.replaceAll(/{|}/g, "").trim())
      // access every key with the corresponding item in the array
      const final = splitted.map((item) => {
        if (item.includes(".")) {
          const splitDots = item.split(".")
          return data[splitDots[0]][splitDots[1]]
        } else {
          return data[item]
        }
      })
      // reconsturcted Again
      const reconstructed = compact(final).join(" | ")
      document.title =
        reconstructed ||
        `${title ? title : moduleName} ${newPageType.replace("-", "")}`
    } else {
      document.title = `${
        loading
          ? "Loading..."
          : `${title ? title : moduleName} ${newPageType.replace("-", "")}`
      }`
    }
  }, [JSON.stringify(data), indexName, moduleName, loading, title, newPageType])

  return (
    <Fragment>
      {loading ? (
        <Skeleton
          active
          round={true}
          title={true}
          paragraph={{
            rows: 0,
          }}
        />
      ) : (
        <Breadcrumbs
          title={`${prefix ? prefix : ""} ${title ? title : moduleName} ${
            titleAddOn ? titleAddOn : ""
          } ${newPageType}`}
          breadcrumbItem={
            customTitle
              ? customTitle
              : `${prefix ? prefix : ""} ${title ? title : moduleName} ${
                  titleAddOn ? `# ${titleAddOn}` : ""
                } ${newPageType}`
          }
          customBackLink={customBackLink}
        >
          {children}
        </Breadcrumbs>
      )}
    </Fragment>
  )
}

export default PageBreadCrumbAndTitle
