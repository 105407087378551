import React from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"
import { convertNameToRoute } from "utils"

const SidebarItem = ({ item }) => {
  return (
    // <Link
    //   to={{
    //     /* if the name comming from DB consist of two words
    //     replace the space between them into -*/
    //     pathname: `/${item.name.toLowerCase().replace(" ", "-")}`,
    //     // state: item.sub_modules.length > 0 ? item.sub_modules : item,
    //     state: item,
    //   }}
    // >
    // <Link to={`/${item.name.toLowerCase().replace(" ", "-")}`}>
    // <Link to={`/${item.name.toLowerCase().replace(/\s/g, "-")}`}>
    <Link to={`/${convertNameToRoute(item.name)}`}>
      <i className={item.icon ? item.icon : "bx bxs-camera-off"}></i>
      <span>{item.title}</span>
    </Link>
  )
}

SidebarItem.propTypes = {
  item: PropTypes.object,
}
export default SidebarItem
