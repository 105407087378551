import React, { Fragment, useEffect, useMemo, useState } from "react"
import { TreeSelect } from "antd"
import FormInputError from "./FormInputError"
import { apiErrorrHandler, NotificationMessage } from "utils"
import DropDownSkeleton from "./DropDownSkeleton"
import { useLocation } from "react-router-dom"
import { compact, defaults, flatten, omit } from "lodash"
import DropdownChangeModal from "../Modals/DropdownChangeModal"
import { generalRequest } from "helpers/General/general_request"
import { bigPagination } from "constants/TPA"
import useSWR from "swr"
import { usePageType } from "hooks"

/* 
Send The multiple prop as true to make it multiple 
or (boolean false or not send it) to make single select
*/
const SearchTree = ({
  id,
  name,
  value,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  onChange,
  onBlur,
  invalid,
  error,
  wrapperClassNames,
  treeData: propTreeData,
  multiple,
  fieldNames,
  getTreeData,
  recordId,
  showParent,
  searchQuery: propSearchQuery,
  urlQuery,
  dynamicUrlQuery,
  specificKey,
  manualDisable,
  setSelectLabel,
  horizontalLayout,
  customOnChange,
  noLabel,
  labelInValue,
  disableTree,
  readOnly,
  showPopupOnChange,
  generateCustomLabels,
  onSelectData,
  validateOnSelect,
  onSelectValidateData,
  url,
  disableFetch,
  disableSorting,
  disablePaginate,
  disableViewPage = false,
}) => {
  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const fetcher = async (url, params) => {
    const isURLHasNum =
      compact(url.split("/").map((item) => +item)).length > 0 ? true : false
    let additionalParams = isURLHasNum
      ? {}
      : disableSorting
      ? { paginate: bigPagination, view: !disableTree ? "tree" : "" }
      : {
          paginate: bigPagination,
          sort_type: "asc",
          sort_by: fieldNames.label,
          view: !disableTree ? "tree" : "",
        }

    additionalParams = disablePaginate
      ? omit(additionalParams, ["paginate"])
      : additionalParams
    const res = await generalRequest({
      url,
      ...additionalParams,
      ...params,
    })

    if (res) {
      if (res.items) {
        return specificKey
          ? res.items.map((item) => ({ ...item[specificKey] }))
          : res.items
      }
      if (res.item) {
        return specificKey ? res.item[specificKey] : res.item
      }
    }
  }

  const params = defaults(urlQuery, dynamicUrlQuery)
  const {
    isLoading: loading,
    error: err,
    data,
  } = useSWR(
    () => (url && !disableFetch ? [url, params] : null),
    ([url, params]) => fetcher(url, params),
    {
      revalidateOnFocus: false,
    }
  )

  const handleSelect = (selectedValue, node, extra) => {
    console.log("-------onSelect---------")
    console.log("selcted", selectedValue)
    console.log("node", node)
    console.log("exrea", extra)
    // if (validateOnSelect && onSelectData) {
    //   console.log("selected", `focused_field:${name}`)
    //   onSelectData(name, selectedValue)
    // }
    // if (showPopupOnChange) {
    //   setIsDropdownChangeModal(true)
    // }
    if (onSelectData) {
      onSelectData(node)
    }
  }
  const handleChange = (selectedValue, label, extra) => {
    console.log("-------onChange---------")
    console.log(
      "🚀 ~ file: SearchTree.jsx:51 ~ handleChange ~ selectedValue:",
      selectedValue
    )
    console.log("changed label", label)
    console.log("extra", extra)

    if (validateOnSelect && onSelectValidateData) {
      console.log("selected", `focused_field:${name}`)
      onSelectValidateData(name, selectedValue)
    }

    // if (onSelectData) {
    //   onSelectData(selectedValue)
    // }

    // store the previous value
    // if (extra) {
    //   setPreviousValue(extra.preValue[0].value ? extra.preValue[0].value : "")
    // } else {
    //   setPreviousValue(selectedValue)
    // }

    if (setSelectLabel) {
      onChange(
        `${name}`,
        Array.isArray(selectedValue)
          ? selectedValue.map((el) => ({ value: el.value, label: el.label }))
          : selectedValue
          ? { label: selectedValue.label, value: selectedValue.value }
          : ""
      )
      setSelectLabel(selectedValue.label)
    } else {
      onChange(
        `${name}`,
        Array.isArray(selectedValue)
          ? selectedValue.map((el) => el)
          : selectedValue
          ? selectedValue
          : ""
      )
    }
  }
  const handleBlur = () => {
    onBlur(`${name}`, true)
  }

  const location = useLocation()
  const { pathname } = location
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2]

  const { isViewPage: isInViewPage } = usePageType()

  const isViewPage = isInViewPage && !disableViewPage

  const [treeData, setTreeData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [previousValue, setPreviousValue] = useState("")
  // for showing pop up before changing
  const [isDropdownChangeModal, setIsDropdownChangeModal] = useState(false)

  // getting the data from the API
  const getData = async () => {
    setIsLoading(true)
    try {
      let groupRes
      if (recordId) {
        groupRes = await getTreeData(recordId, urlQuery)
        if (showParent) {
          if (groupRes.items) {
            setTreeData(
              specificKey
                ? groupRes.items.map((item) => ({ ...item[specificKey] }))
                : groupRes.items
            )
          } else {
            setTreeData(
              specificKey ? groupRes.item[specificKey] : groupRes.item
            )
          }
        } else {
          // setTreeData([groupRes.item])
          setTreeData(groupRes.item?.children)
        }
      } else {
        groupRes = await getTreeData({
          sort_type: "asc",
          sort_by: fieldNames.label,
          ...urlQuery,
          ...dynamicUrlQuery,
          view: !disableTree ? "tree" : "",
          per_page: 1000,
        })
        // setTreeData(groupRes?.items)
        setTreeData(
          specificKey
            ? flatten(groupRes?.items.map((item) => item[specificKey]))
            : groupRes?.items
        )
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (getTreeData) {
      getData()
    }
  }, [recordId, JSON.stringify(dynamicUrlQuery)])

  useEffect(() => {
    if (url && data) {
      setTreeData(data)
    }
  }, [data, url])

  useEffect(() => {
    if (disableFetch) {
      setTreeData([])
    }
  }, [disableFetch])

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {/* in some cases we need to not show the label */}
      {!noLabel && (
        <Fragment>
          <label
            htmlFor={id}
            className={`form-label text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${labelClass ? labelClass : ""}`}
          >
            {label}
          </label>
          {/* Reload Button */}
          {labelClass.includes("reload-button") ? (
            <button
              className="btn btn-transparent p-0 border-0 m-0 d-inline-block ms-3"
              aria-label="Reload Options"
              type="button"
              onClick={() => getData()}
            >
              <i className="mdi mdi-reload font-size-18"></i>
            </button>
          ) : null}
        </Fragment>
      )}

      <div className={horizontalLayout && inputClass}>
        {isLoading || loading ? (
          <DropDownSkeleton />
        ) : (
          <div
            className={`form-control px-0 ${invalid ? "border-danger" : ""} ${
              inputClass ? inputClass : ""
            } ${isViewPage ? "border-0" : ""}`}
            style={{ paddingBlock: "2px" }}
          >
            <TreeSelect
              disabled={
                isLoading ||
                manualDisable ||
                (isViewPage && multiple) ||
                readOnly
              }
              // onDropdownVisibleChange={open => setIsOpen(open)}
              // hide the search in view page
              showSearch={!isViewPage && !readOnly}
              // hide the arrow in view page
              showArrow={!isViewPage && !readOnly}
              name={name}
              id={id}
              placeholder={placeholder}
              style={{
                width: "100%",
              }}
              bordered={false}
              value={value || null}
              // hide the menu in view page and display it otherwise
              dropdownStyle={
                isViewPage || readOnly
                  ? { display: "none" }
                  : {
                      maxHeight: 400,
                      overflow: "auto",
                    }
              }
              // disable clear in view page
              allowClear={!isViewPage && !readOnly}
              multiple={multiple}
              // loading={isLoading}
              // onClear={() => onSelectData && onSelectData("")}
              // treeNodeFilterProp={fieldNames && "name"}
              treeNodeFilterProp={fieldNames && fieldNames["label"]}
              // treeDefaultExpandAll
              onChange={customOnChange ? customOnChange : handleChange}
              onBlur={handleBlur}
              treeData={
                generateCustomLabels
                  ? generateCustomLabels(treeData).map((item) => ({
                      ...item,
                      selectable: item?.disabled ? false : true,
                    }))
                  : propTreeData
                  ? propTreeData
                  : treeData
              }
              // treeDataSimpleMode={true}
              labelInValue={setSelectLabel || labelInValue ? true : false}
              fieldNames={fieldNames && fieldNames}
              onSelect={handleSelect}
            />
          </div>
        )}
        {/* Error Message */}
        {invalid && !isLoading && !isViewPage && !readOnly ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>

      {/* Modal that show up when user want to select another option from select menu */}
      {/* {isDropdownChangeModal && (
        <DropdownChangeModal
          show={isDropdownChangeModal}
          onConfirmClick={() => {
            setSelectAnotherOption(true)
            setIsDropdownChangeModal(false)
          }}
          onCloseClick={() => {
            setIsDropdownChangeModal(false)
            setSelectAnotherOption(false)
            handleChange(previousValue)
          }}
        />
      )} */}
    </div>
  )
}

export default SearchTree
