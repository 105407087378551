import { Select } from "antd"
import { DatePickerInput } from "components/TP/Common/Forms"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import React, { Fragment } from "react"
import { Input } from "reactstrap"

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

export const DefaultColumnFilter = ({
  column,
  search,
  isActiveColumn,
  loading,
}) => {
  return loading ? (
    <MultiSkeleton length={1} />
  ) : column.canFilter ? (
    <Fragment>
      {(() => {
        switch (column.fieldType) {
          case "boolean":
            return (
              <Select
                className="ColumnFilter"
                placeholder={`search ...`}
                defaultValue={isActiveColumn ? "1" : ""}
                options={[
                  { label: "Yes", value: "1" },
                  { label: "No", value: "0" },
                  { label: "All", value: "" },
                ]}
                onChange={async (e) => {
                  const value =
                    e === "1" || e === "0"
                      ? e
                      : isActiveColumn
                      ? "undefined"
                      : undefined
                  await column.setFilter(value)
                  search(column || undefined, e)
                }}
              />
            )
          case "date":
            return (
              <DatePickerInput
                noLabel={true}
                onBlur={() => {}}
                customOnChange={async (date, dateStr) => {
                  await column.setFilter(dateStr || undefined)
                  search(column || undefined, dateStr)
                }}
                removeIcon={true}
                removeDisable={true}
                clearIcon={true}
                inFilter={true}
                // notCloseOnSelect={true}
                removeOnClose={true}
                placeholder="Select Date"
                wrapperClassNames={
                  column?.disableColumnSearch ? "opacity-0" : "opacity-1"
                }
              />
            )

          default:
            return (
              <Input
                className={`ColumnFilter ${
                  column.disableColumnSearch ? "opacity-0" : "opacity-1"
                }`}
                onChange={async (e) => {
                  const inputValue = e.target.value
                  await column.setFilter(inputValue || undefined)
                  inputValue.length >= 2 || !inputValue
                    ? search(column || undefined, inputValue)
                    : () => {
                        return
                      }
                }}
                placeholder={`search ...`}
              />
            )

          // default:
          //   return null
        }
      })()}
    </Fragment>
  ) : (
    ""
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}
