import { Breadcrumbs, Card } from "@material-ui/core"
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import React, { Fragment, useEffect, useState } from "react"
import { CardBody, Col, Container, Row } from "reactstrap"
import { showPolicyPayment } from "helpers/Finance/PolicyPayment"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { useLocation } from "react-router-dom"
import { showCompany } from "helpers/Settings/Company"
import { useDecryptId } from "hooks"
import PaymentReceiptPdf from "./PaymentReceiptPdf"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"

const PaymentReceiptPrint = () => {
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [paymentReceipts, setPaymentReceipts] = useState(
    location.state?.paymentReceipts || []
  )
  const getCompany = async () => {
    setLoading(true)
    try {
      const companyRes = await showCompany()
      if (companyRes) {
        const updatedReceipts = paymentReceipts.map((receipt) => ({
          ...receipt,
          company: companyRes.item,
        }))

        setPaymentReceipts(updatedReceipts)
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getCompany()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <PageBreadCrumbAndTitle pageType="Print" /> */}
          <Breadcrumbs title="Receipt" />

          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <MultiSkeleton length={24} />
                  ) : (
                    paymentReceipts && (
                      <PDFViewer
                        showToolbar={true}
                        style={{ width: "100%", height: "100vh" }}
                      >
                        <PaymentReceiptPdf paymentReceipts={paymentReceipts} />
                      </PDFViewer>
                    )
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentReceiptPrint
