import { ADD_DATA, REMOVE_DATA, ADD_ERROR, REMOVE_ERROR } from "./actionTypes"

const initialState = {
  claimFormData: null,
  claimErrors: null,
}

const SharedData = (state = initialState, action) => {
  switch (action.type) {
    // Add Data
    case ADD_DATA:
      state = {
        ...state,
        ...action.payload,
      }
      break
    // Add Error
    case ADD_ERROR:
      state = {
        ...state,
        claimErrors: {
          ...state.claimErrors,
          ...action.payload,
        },
      }
      break
    //remove Error
    case REMOVE_ERROR:
      // delete the error if already there
      const removedError =
        state.claimErrors && state.claimErrors[action.payload]
          ? delete state.claimErrors[action.payload]
          : state.claimErrors
      state = {
        ...state,
        claimErrors: {
          ...removedError,
        },
      }
      break
      // case REMOVE_DATA:
      //   state = {
      //     ...state,
      //     isModal: false,
      //     message: "",
      //     title: "",
      //     confirmButtonTitle: "",
      //     closeButtonTitle: "",
      //     includeTwoButtons: false,
      //     confirmCallback: null,
      //     closeCallback: null,
      //   }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SharedData
