import React from "react"
import { Spinner } from "reactstrap"

const ModalSpinner = ({ color }) => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Spinner
        color={color ? color : "success"}
        type="grow"
        style={{
          height: "3rem",
          width: "3rem",
        }}
      >
        Loading
      </Spinner>
    </div>
  )
}

export default ModalSpinner
