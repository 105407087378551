import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { DatePickerInput } from "../Forms"
import { NotificationMessage, apiErrorrHandler, formatedTodayData } from "utils"
import moment from "moment"
import { handleAddEvent } from "utils/API/addEventManage"
import { useSelector } from "react-redux"
import { addEventManager } from "helpers/CommonData/EventManager"

const ActivationModal = ({
  show,
  onCloseClick,
  onConfirmClick,
  isActivating,
  setIsActivationModal,
  record,
  t,
}) => {
  const [effectiveDate, setEffectiveDate] = useState(formatedTodayData())

  const { fields } = useSelector((state) => ({
    fields: state?.Module?.actions?.fields,
  }))

  // grap the is_active field id from permission level 2 fields
  const isActiveFieldId =
    fields?.length > 0 &&
    fields?.find((field) => field.index_name === "is_active")?.id

  // add event
  const addEvent = async () => {
    try {
      await addEventManager({
        execution_date: effectiveDate,
        resource_id: record?.id,
        data: [
          {
            new_value: record?.is_active === 1 ? 0 : 1,
            field_id: isActiveFieldId,
          },
        ],
      })
      NotificationMessage("Success", "update")
      setIsActivationModal(false)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    }
  }
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      {/* Modal Header */}

      <div className="modal-header">
        {/* Modal Header Title */}

        <h5 className="modal-title mt-0" id="myModalLabel">
          {record?.is_active == "0" ? t("Activation") : t("Deactivation")}
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <DatePickerInput
          label={t("Effective Date")}
          value={effectiveDate}
          onChange={(date, dateStr) => setEffectiveDate(dateStr)}
          onBlur={() => console.log("blured")}
          disablePastDates={true}
        />
        {/* <p className="text-muted font-size-16 mb-4 text-left">
          {record?.is_active == "0"
            ? t("Are you sure you want to activate this record?")
            : t("Are you sure you want to deactivate this record?")}
        </p> */}
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-center mb-0">
          <button
            type="button"
            className="btn btn-light"
            onClick={onCloseClick}
          >
            {t("Close")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            // if effective day === today date (Activate or deactivate the record directly)
            // if effective day > today date (Add event manager)

            onClick={
              effectiveDate === moment(new Date()).format("YYYY-MM-DD")
                ? onConfirmClick
                : () => addEvent()
            }
            disabled={isActivating}
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    </Modal>
  )
}

ActivationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  isActivating: PropTypes.bool,
}
export default withTranslation()(ActivationModal)
