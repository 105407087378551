import { request } from "helpers/api_helper"
import * as url from "helpers/url_helper"

/*
Policy Payments
*/

// add new Policy Payment

// url
export const addPolicyPayment = (data) =>
  request({ url: url.POLICYPAYMENT, method: "POST", data })

export const addMemberReceipt = (data) =>
  request({ url: `${url.POLICYPAYMENT}/bulk`, method: "POST", data })

// get all PolicyPayment with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllPolicyPayments = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
}) =>
  request({
    url: url.POLICYPAYMENT,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
    },
  })

// show Policy Payment
//url/{id}

export const showPolicyPayment = (paymentId) =>
  request({ url: `${url.POLICYPAYMENT}/${paymentId}`, method: "GET" })

// update Policy Payment
//url/{id}

export const updatePolicyPayment = (data) =>
  request({ url: `${url.POLICYPAYMENT}/${data.id}`, method: "PUT", data })

// delete Policy Payment
//url/{id}
export const deletePolicyPayment = (paymentId) =>
  request({ url: `${url.POLICYPAYMENT}/${paymentId}`, method: "DELETE" })

// activate Payemn tOrder
//url/{id}/activate
export const activatePolicyPayment = (paymentId) =>
  request({ url: `${url.POLICYPAYMENT}/${paymentId}/activate`, method: "POST" })

// deactivate Policy Payment
//url/{id}/deactivate
export const deactivatePolicyPayment = (paymentId) =>
  request({
    url: `${url.POLICYPAYMENT}/${paymentId}/deactivate`,
    method: "POST",
  })
