import { TOGGLeCHANGE } from "./actionTypes"

const initialState = {
  changed: false,
}

const TabChange = (state = initialState, action) => {
  switch (action.type) {
    // Add New JobTitle
    case TOGGLeCHANGE:
      state = {
        ...state,
        changed: action.payload.value,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default TabChange
