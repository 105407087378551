import { request } from "../../api_helper"
import * as url from "../../url_helper"
/*
Products
*/

// add new Prodcut

// url
export const addProduct = (data) =>
  request({ url: url.PRODUCTS, method: "POST", data })

// get all Prodcuts with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllProducts = ({
  payer_id,
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.PRODUCTS,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      payer_id,
      ...rest,
    },
  })

// show Product
//url/{id}

export const showProduct = (productId) =>
  request({ url: `${url.PRODUCTS}/${productId}`, method: "GET" })

// show Product Benefits
//url/{id}

export const showProductBenefits = (productId, params) =>
  request({
    url: `${url.PRODUCTS}/${productId}/family-benefits`,
    method: "GET",
    params,
  })

// update Product
//url/{id}

export const updateProduct = (data) =>
  request({ url: `${url.PRODUCTS}/${data.id}`, method: "PUT", data })

// delete Product
//url/{id}
export const deleteProduct = (productId) =>
  request({
    url: `${url.PRODUCTS}/${productId}`,
    method: "DELETE",
  })

// activate Product
//url/activate/{id}
export const activateProduct = (productId) =>
  request({
    url: `${url.PRODUCTS}/${productId}/activate`,
    method: "POST",
  })

// deactivate Product
//url/deactivate/{id}
export const deactivateProduct = (productId) =>
  request({
    url: `${url.PRODUCTS}/${productId}/deactivate`,
    method: "POST",
  })

// copy Product
//url/copy/{id}
export const copyProduct = (data) =>
  request({
    url: `${url.PRODUCTS}/${data.id}/copy`,
    method: "POST",
    data,
  })
