import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { DatePickerInput } from "../Forms"
import { NotificationMessage, apiErrorrHandler, formatedTodayData } from "utils"

import { addEventManager } from "helpers/CommonData/EventManager"
import { buttonsTitles } from "common/data"
import moment from "moment"

const ScheduleEventModal = ({
  show,
  onCloseClick,
  onConfirmClick,
  resourceId,
  newValue,
  fieldId,
  t,
}) => {
  const [effectiveDate, setEffectiveDate] = useState(formatedTodayData())
  const [isAdding, setIsAdding] = useState(false)
  // add event
  const addEvent = async () => {
    setIsAdding(true)
    try {
      await addEventManager({
        execution_date: effectiveDate,
        resource_id: resourceId,
        data: [
          {
            new_value: newValue,
            field_id: fieldId,
          },
        ],
      })
      NotificationMessage("Success", "update")
      setIsAdding(false)
      onCloseClick()
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
      setIsAdding(false)
    }
  }
  return (
    <Modal
      size="md"
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      backdrop="static"
    >
      {/* Modal Header */}

      <div className="modal-header">
        {/* Modal Header Title */}

        <h5 className="modal-title mt-0" id="myModalLabel">
          {t("Schedule Event")}
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <DatePickerInput
          label={t("Effective Date")}
          value={effectiveDate}
          onChange={(date, dateStr) => setEffectiveDate(dateStr)}
          onBlur={() => console.log("blured")}
          disablePastDates={true}
        />
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-center mb-0">
          <button
            type="button"
            className="btn btn-light"
            onClick={onCloseClick}
            disabled={isAdding}
          >
            {buttonsTitles.close}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={
              effectiveDate === moment(new Date()).format("YYYY-MM-DD")
                ? onConfirmClick
                : () => addEvent()
            }
            disabled={isAdding}
          >
            {buttonsTitles.confirm}
          </button>
        </div>
      </div>
    </Modal>
  )
}

ScheduleEventModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  isActivating: PropTypes.bool,
}
export default withTranslation()(ScheduleEventModal)
