import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  notifications: [],
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: action.payload,
        error: "",
      }
      break
    case GET_NOTIFICATIONS_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default notifications
