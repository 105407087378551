import React, { Fragment } from "react"
import { Badge, Modal } from "reactstrap"
import { withTranslation } from "react-i18next"
import useFetchSingleRecord from "hooks/API/useFetchSingleRecord"
import { showBeneficarySpecialNotes } from "helpers/Production/Policies/beneficaries_helper"
import MultiSkeleton from "../MultiSkeleton"
const SpecialNotesModal = ({
  show,
  onCloseClick,
  t,
  beneficaryId,
  beneficary,
}) => {
  const { results: beneficiarySpecialNotes, loading } = useFetchSingleRecord(
    showBeneficarySpecialNotes,
    beneficaryId
  )

  // const isVIP =
  //   beneficiarySpecialNotes &&
  //   Array.isArray(beneficiarySpecialNotes.dmpd_programs) &&
  //   beneficiarySpecialNotes.dmpd_programs.length > 0
  const isVIP =
    beneficiarySpecialNotes &&
    beneficary &&
    beneficary.client_class &&
    beneficary.client_class.id === 449

  const NoDataMessage = () => {
    // return <p className="text-info">Sorry,There is No Data For Now</p>
    return <p className="text-info"></p>
  }

  return (
    <Modal
      size="md"
      isOpen={show}
      toggle={onCloseClick}
      // centered={notCentered ? false : true}
      backdrop="static"
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        {loading ? (
          <MultiSkeleton length={1} />
        ) : (
          <h5 className="modal-title mt-0" id="myModalLabel">
            Special Notes
            {isVIP ? (
              <Badge color="warning" className="ms-1 px-2 py-1">
                {beneficary?.client_class?.name}
              </Badge>
            ) : null}
          </h5>
        )}
      </div>

      {loading && !beneficiarySpecialNotes && (
        <div className="modal-body">
          <MultiSkeleton length={12} />
        </div>
      )}

      {/* Modal Body */}
      {beneficiarySpecialNotes && (
        <div className="modal-body">
          {/* <h5>Insurer</h5> */}
          <h5>{beneficary?.policy?.payer?.first_name || "Insurer"}</h5>

          {beneficiarySpecialNotes.payer_special_notes ? (
            <p>{beneficiarySpecialNotes.payer_special_notes}</p>
          ) : (
            <NoDataMessage />
          )}
          <h5>{beneficary?.policy?.name || "Group"}</h5>
          {beneficiarySpecialNotes.policy_remarks ? (
            <p>{beneficiarySpecialNotes.policy_remarks}</p>
          ) : (
            <NoDataMessage />
          )}
          <h5>{beneficary?.name || "Patient"}</h5>
          {beneficiarySpecialNotes.special_notes ? (
            <p>{beneficiarySpecialNotes.special_notes}</p>
          ) : (
            <NoDataMessage />
          )}
          <h5>Memo</h5>
          <p>{beneficary?.memo}</p>

          {/* {isVIP ? (
          <h5 className="bg-warning text-white p-1 ps-2 fw-bold font-size-16 rounded-2 mb-2">
            VIP programs
          </h5>
          ) : null}
          {beneficiarySpecialNotes.dmpd_programs.map(progarm => (
            <p key={progarm.id}>{progarm.name}</p>
          ))} */}
        </div>
      )}
      {/* Modal Footer */}
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={onCloseClick}
          disabled={loading}
        >
          {t("Understood")}
        </button>
      </div>
    </Modal>
  )
}

export default withTranslation()(SpecialNotesModal)
