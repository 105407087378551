import { DMPDModuleID, priorityCodesModuleID } from "utils/modulesIds"
import {
  GET_ACTIONS,
  GET_ACTIONS_SUCCESS,
  GET_ACTIONS_FAIL,
  GET_SUBMODULES,
  GET_SUBMODULES_SUCCESS,
  GET_SUBMODULES_FAIL,
  GET_SECTION,
  GET_SECTION_SUCCESS,
  GET_SECTION_FAIL,
} from "./actionTypes"

const initialState = {
  id: null,
  error: "",
  loading: false,
  actions: null,
  fields: [],
  section: null,
  subModules: [],
  name: "",
  has_workflow: null,
}

const actions = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ACTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        actions: action.payload,
        // name: action.payload.type === "PAGE" ? action.payload.name : state.name,
        name:
          action.payload.id !== DMPDModuleID
            ? action.payload.title
            : state.name,
        id: action.payload.id,
        error: "",
        has_workflow: action.payload.has_workflow,
      }
      break
    case GET_ACTIONS_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    /*
      submodules and fields
      */

    case GET_SUBMODULES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SUBMODULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        fields: action.payload.fields,
        subModules: action.payload.subModules,
        name: action.payload.name,
        id: action.payload.id,
        actions: state.actions
          ? state.actions
          : {
              actions: action.payload.actions,
              name: action.payload.name,
              id: action.payload.id,
            },
        error: "",
        has_workflow: action.payload.has_workflow,
      }
      break
    case GET_SUBMODULES_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    /*
  Sections
  */
    case GET_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        section: action.payload,
        name:
          action.payload.id === priorityCodesModuleID
            ? action.payload.name
            : state.name,
        error: "",
      }
      break
    case GET_SECTION_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default actions
