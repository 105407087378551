import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "components/Common/ChartsDynamicColor"

const FOBChart = ({ dataColors, KPI }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors)
  const series = [56, 38, 26, 10]
  const options = {
    labels: KPI.data.map(item => item.benefit_name),
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }

  const colors = JSON.parse(dataColors).map(color => color.replace("--bs", ""))
  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">{KPI.name}</h4>

            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={KPI.data.map(item => item.count)}
                  type="donut"
                  height={260}
                  className="apex-charts"
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                {KPI.data.length > 0 &&
                  KPI.data.map((item, index) => (
                    <Col xs={12 / KPI.data.length} key={item.benefit_slug}>
                      <div className="mt-4">
                        <p className="mb-2 text-truncate">
                          <i
                            className={`mdi mdi-circle text${colors[index]} me-1`}
                          />
                          {item.benefit_slug}
                        </p>
                        <h5>{item.count}</h5>
                      </div>
                    </Col>
                  ))}
                {/* <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-success me-1" /> Product
                      B
                    </p>
                    <h5>$ 1,763</h5>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi mdi-circle text-danger me-1" /> Product
                      C
                    </p>
                    <h5>$ 973</h5>
                  </div>
                </Col> */}
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default FOBChart
