// settings
export const settingsPaths = {
  notificationTemplate: "/nofitications-templates",
  claimRule: "/claim-rule",
}

// common data
export const commonDataPaths = {
  currencies: "/currency",
  events: "/event-manager",
}

// stakeholders
export const stakeholderPaths = {
  reinsurer: "/reinsurer",
  healthcare_providers: "/healthcare_provider",
  beneficiaryList: "/beneficiary-list",
  subBeneficiaryList: "/sub-beneficiary-list",
  policyMember: "/policy-member",
  broker: "/broker",
  customerList: "/customers",
}

// production
export const productionPaths = {
  products: "/product",
  policy: "/policy",
}

// networks
export const networkPaths = {
  networks: "/networks",
}
// finance
export const financePaths = {
  invoice: "/invoice",
  policyPayment: "/policy-payment",
  commissionPayment: "/commission-payment",
  collections: "/collection",
  memberReceipt: "/member-receipt",
}

// dashboard
export const dashboardPaths = {
  claimDashboard: "/claim-dashboard",
  productionDashboard: "/production-dashboard",
}

// general
export const generalPaths = {
  // for notifications module in side bar
  notificationsLogs: "/notification-logs",
  // for the notifications View All button in header dropdown
  dropdownNotifications: "/notifications-logs",
  productionEvents: "/production-events",
  tasks: "/tasks",
}
// reports
export const reportPaths = {
  dynamicReport: "/dynamic-report",
  blackListedReport: "/blacklisted-report",
  dueToExpirtyReport: "/policies-due-to-expire",
  agentComissionReport: "/agent-commission",
  notRenewedReports: "/policies-not-renewed",
  agentPoliciesReports: "/agent-policies",
}

export const claimPaths = {
  claimForm: "/claim-form",
  viewProcessing: "view-processing",
}
