export const buttonsTitles = {
  // beneficiary buttons
  FamilyCompute: "Family Compute",
  memberCompute: "Member Compute",
  addMember: "Add Member",
  viewSub: "View Dependencies",
  createPolicy: "Create Policy",
  printInstallments: "Print Installments",
  printCard: "Print Card",
  printContract: "Print Contract",
  // policies
  addBroker: "Add Broker",
  createPolicy: "Create Policy",
  saveAndCompute: "Save & Compute",
  saveAndValidate: "Save & Validate",
  ComputeAndValidate: "Compute & Validate",
  selectPlans: "Select Plans",
  select: "Select",
  viewPolicies: "View Policies",
  printCards: "Print Cards",

  //general
  save: "Save",
  saveChanges: "Save Changes",
  loadMore: "Load More",
  scheduleSave: "Schedule Save",
  confirm: "Confirm",
  close: "Close",
  downloadSample: "Download Sample",
  upload: "Upload",
  update: "Update",
  medicalDecl: "Medical Declaration",
  addNew: "Add New",
  remove: "Remove",
  reject: "Reject",
  forceSave: "Force Save",
  cancel: "Cancel",
  calculate: "Calculate",
  view: "View",
  actions: "Actions",
  proceed: "Proceed",
  decline: "Decline",
  approve: "Approve",
  filter: "Filter",
  clear: "Clear",
  back: "Back",
  yes: "Yes",
  reload: "Reload",
  no: "No",
  understood: "Understood",
  apply: "Apply",
  compute: "Compute",
  delete: "Delete",
  print: "Print",
  exportExcel: "Export To Excel",
  // product premium
  custsomAge: "Add Custom Age Bracket",
  ageBracket: " Age Bracket",

  // policy receipts
  saveAndPrint: "Save & Print",

  //product
  productSummary: "Product Summary",
  // policy members
  addNewHolder: "Add New Holder",
  printContract: "Print Contract",
  endrosementHistory: "Endrosement History",
  specialNotes: "Special Notes",
  viewPremium: "view Premium",

  // Endorsement
  endorsement: "Endorsement",
  endorsements: "Endorsements",
  additional: "Addition Endorsement",
  // policy renewal
  changePlans: "Change Plans",
  customRenewal: "Custom Renewal",
  renew: "Renew",
  renewalOffer: "Download Renewal Offer",
  copyTask: "FWD Task",
  changeStatus: "Change Status",
}

export const staticLabels = {
  grantView: "Grant View",
  //tasks
  assginedByMe: "Assigned By Me",
  assginedToMe: "Assigned To Me",
  //calender
  uncompletedTasks: "Show Uncomplete Tasks",
  showAll: "Show All",
}
export const modalsStates = {
  update: "UPDATE_MODAL",
  add: "ADD_MODAL",
  view: "VIEW_MODAL",
  stakeholderStatus: "STATUS_MODAL",
  viewPremium: "VIEW_PREMIUM_MODAL",
  memberCompute: "MEMBER_COMPUTE_MODAL",
  familyCompute: "FAMILY_COMPUTE_MODAL",
  sepcialNotes: "SPECIAL_NOTES_MODAL",
  selectPlans: "SELECT_PLANS_MODAL",
  printInvoice: "PRINT_INVOICE_MODAL",
  endrosementModal: "ENDORSEMNET_MODAL",
  additionalModal: "ADDITIONAL_MODAL",
  policyPrintContract: "POLICY_SELECT_MEMBER_MODAL",
  printCard: "PRINT_CARD_MODAL",
  cobaby: "COBABY_MODAL",
}

export const staticPlaceholders = {
  // index policies
  enterMemberRef: "Please Enter Member Reference",
  selectDate: "Select Date",
  // premiums
  percentage: "Enter Percentage",
  // policy renewal
  totalDiscountAmount: "Total Discount Amount",
  usd: "USD",
  amountPerMember: "Amount Per Member",
}

export const staticFieldLabels = {
  // general
  effectiveDate: "Effective Date",
  // dynamic report (step three) filters
  field: "Field",
  operator: "Operator",
  value: "Value",
  memberName: "Member Name",

  // endorsement modal
  oldTotalPrice: "Old Total Price",
  newTotalPrice: "New Total Price",
  oldPremium: "Old Premium",
  newPremium: "New Premium",
  difference: "Difference",

  policyHolder: "Policy Holder",
  newpolicyHolder: "New Policy Holder",
  // product premium
  changePercentage: "Change Percentage",

  // policy renewal
  sumOfPremiums: "Sum of Premiums",
  overPremiumAmount: "Over Premium Amount",
  policyIssueFees: "Policy Issue Fee",
  endDate: "End Date",
  inceptionDate: "Inception Date",
  productName: "Product Name",
  discountAmount: "Discount Amount",
}

export const modalHeaders = {
  // general
  warning: "Warning",
  medicalFile: "Medical Files",
  copyAgreement: "Copy Agreement",
  premiumValidation: "Premium Validation",
  //upload contract prices
  confirmLineOfBussiness: "Confirm Line of Bussiness",
  // Eligibility Validation
  verificationNotes: "Verification Notes",
  // compute and validate policy
  computePolicy: "Compute Policy",

  // premiums
  confirmUploadPremiums: "Confirm Upload Premiums",
  // renew policy
  renewalPolicy: "Renewal Policy",
  changePlans: "Change Plans",

  // premiums
  updatePercentage: "Update Percentage",
}

export const modalTitles = {
  warning: "Warning",
  saveEndorsement: "Save Endorsement",
}
