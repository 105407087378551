import toastr from "toastr"
import "toastr/build/toastr.min.css"
import parse from "html-react-parser"
export function generateToast(
  type,
  message,
  title,
  closeButton,
  positionClass
) {
  toastr.options = {
    positionClass: positionClass ? positionClass : "toast-top-center",
    closeButton: closeButton ? closeButton : true,
    // timeOut: timeOut,
    // extendedTimeOut: extendedTimeOut,
    // debug: debug,
    // progressBar: progressBar,
    // preventDuplicates: preventDuplicates,
    // newestOnTop: newestOnTop,
    // showEasing: showEasing,
    // hideEasing: hideEasing,
    // showMethod: showMethod,
    // hideMethod: hideMethod,
    // showDuration: showDuration,
    // hideDuration: hideDuration
  }
  if (type.toLowerCase() === "info") return toastr.info(parse(message), title)
  else if (type.toLowerCase() === "warning")
    return toastr.warning(parse(message), title)
  else if (type.toLowerCase() === "error")
    return toastr.error(parse(message), title)
  else return toastr.success(parse(message), title)
}

// toastr.warning(
//     "There Are some data will be changing after this action!",
//     "Warning",
//     {
//       positionClass: "toast-top-center",
//       closeButton: true,
//     }
//   )
