import React from "react"
import { FormFeedback } from "reactstrap"

const FormInputError = ({ error, selectError }) => {
  return selectError ? (
    <span className="font-size-10 text-danger">{error}</span>
  ) : (
    <FormFeedback type="invalid">{error}</FormFeedback>
  )
}

export default FormInputError
