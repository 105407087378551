import { addEventManager } from "helpers/CommonData/EventManager"
import { NotificationMessage } from "utils/NotificationMessage"
import { apiErrorrHandler } from "utils/apiErrorHandler"

// Add Event Manager
export const handleAddEvent = async (
  values,
  setSubmitting,
  resetForm,
  successCb,
  message
) => {
  try {
    await addEventManager({
      // field_id: 345,
      //   values must include execution_date,new_value,resource_id
      ...values,
    })
    NotificationMessage("Success", message ? message : "update")

    if (resetForm) {
      resetForm()
    }
    if (successCb) {
      successCb()
    }
  } catch (error) {
    const errorMessage = apiErrorrHandler(error)
    NotificationMessage("error", errorMessage)
  } finally {
    if (setSubmitting) {
      setSubmitting(false)
    }
  }
}
