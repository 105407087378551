/*
    this hook is to scroll up to the top of 
    the  form window of a validation error happend 
*/

import { useEffect } from "react"

// pass in an array of errors
const useScrollWhenError = error => {
  useEffect(() => {
    if (error) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }
  }, [error])
}

export default useScrollWhenError
