import React from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import ExportTable from "./ExportTable"
import ShowHideColumns from "./ShowHideColumns"

//i18n
import { withTranslation } from "react-i18next"

// custom hook

import useModuleActions from "hooks/useModuleActions"

const TableActionsButtons = ({
  t,
  handleAdd,
  addPageLink,
  batchPageLink,
  showOptions,
  setShowOptions,
  columns,
  fullColumns,
  data,
  sheetName,
  customCSVHeader,
  subModuleId,
  addDropDown,
  isBackPermission,
  backPageLink,
  isUnusedMarkPermission,
  handleAddMarkUnused,
  addOnButtons,
  inCludeExportButton,
  customCSVData,
  tableActionsComponents,
  // ref,
}) => {
  const {
    isAddPermission,
    isTableViewPermission,
    isTableExportPermission,
    isTableFilterPermission,
    isBatchUploadPermission,
    isAddClaimReimbursementPermission,
  } = useModuleActions(subModuleId && subModuleId)

  return (
    <div className="d-flex justify-content-md-end flex-wrap justify-content-start gap-2">
      {tableActionsComponents ? tableActionsComponents : null}

      {addOnButtons &&
        addOnButtons.length > 0 &&
        addOnButtons.map((addOn, index) => (
          <div className="text-sm-end" key={index + addOn.to}>
            {addOn.to ? (
              <Link
                className={`btn ${addOn.bg} ${
                  addOn.disabled ? "disabled" : ""
                }`}
                aria-disabled={addOn.disabled}
                tabIndex={addOn.disabled ? "-1" : "0"}
                // to={addOn.to ? addOn.to : "#"}
                to={addOn.to}
              >
                <i className={`${addOn.icon} me-1`} />
                {addOn.title}
              </Link>
            ) : (
              <button
                className={`btn ${addOn.bg} ${
                  addOn.disabled ? "disabled" : ""
                }`}
                onClick={addOn.onClick && addOn.onClick}
                disabled={addOn.disabled}
              >
                <i className={`${addOn.icon} me-1`} />
                {addOn.title}
              </button>
            )}
          </div>
        ))}

      {/* For Renewal Policies only */}
      {isBackPermission && backPageLink && (
        <div className="text-sm-end">
          <Link className="btn btn-secondary" to={backPageLink}>
            <i className="mdi mdi-keyboard-backspace me-1" />
            {t("Back")}
          </Link>
        </div>
      )}

      {/* Add Claim Reimbursement Icon Button */}
      {isAddClaimReimbursementPermission && addPageLink && (
        <div className="text-sm-end">
          <Link
            to={{ pathname: addPageLink, state: { type_id: 234 } }}
            className="btn btn-primary"
          >
            <i className="mdi mdi-plus me-1" />
            {isAddClaimReimbursementPermission?.name}
          </Link>
        </div>
      )}

      {/* For Claims only */}
      {isUnusedMarkPermission && handleAddMarkUnused && (
        <div className="text-sm-end">
          <button className="btn btn-info" onClick={handleAddMarkUnused}>
            <i className="mdi mdi-block-helper me-1" />
            {t("Mark as Unused")}
          </button>
        </div>
      )}

      {/* Add Drop Down applied only in Invoice Module right now */}

      {isAddPermission && addDropDown && addDropDown}

      {/* Add Button */}
      {isAddPermission && addPageLink && (
        <div className="text-sm-end">
          <Link to={addPageLink} className="btn btn-success">
            <i className="mdi mdi-plus me-1" />
            {t("Add New")}
          </Link>
        </div>
      )}

      {/* Add Link */}
      {isAddPermission && !addPageLink && handleAdd && (
        <div className="text-sm-end">
          <Button
            type="button"
            color="success"
            className="btn"
            onClick={handleAdd}
            aria-label="Add New"
          >
            <i className="mdi mdi-plus me-1" />
            {t("Add New")}
          </Button>
        </div>
      )}

      {/* Add Button */}
      {isBatchUploadPermission && batchPageLink && (
        <div className="text-sm-end">
          <Link to={batchPageLink} className="btn btn-info">
            <i className="mdi mdi-cloud-upload me-1" />
            {/* {t("Batch Upload")} */}
            {isBatchUploadPermission?.name}
          </Link>
        </div>
      )}

      {/* Export Button */}
      {(isTableExportPermission || inCludeExportButton) && (
        <div className="text-sm-end">
          <ExportTable
            data={data}
            sheetName={sheetName}
            customCSVHeader={customCSVHeader}
            customCSVData={customCSVData}
            columns={columns}
            // ref={ref}
          />
        </div>
      )}

      {/* Eye Icon button  (show / hide)*/}
      {isTableViewPermission && (
        <div className="text-sm-end">
          <ShowHideColumns
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            fullColumns={fullColumns}
          />
        </div>
      )}

      {/* filter Icon Button */}
      {isTableFilterPermission && (
        <div className="text-sm-end">
          <Button type="button" color="primary" className="btn">
            <i className="mdi mdi-filter font-size-14" />
          </Button>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(TableActionsButtons)
