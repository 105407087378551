import moment from "moment"

export function findDateDifference(date) {
  let message
  if (date) {
    const seconds = moment().diff(date, "second")
    const minutes = moment().diff(date, "minute")
    const hours = moment().diff(date, "hour")
    const days = moment().diff(date, "days")
    const weeks = moment().diff(date, "week")
    const months = moment().diff(date, "month")
    const years = moment().diff(date, "year")

    if (years > 0 && months >= 12) {
      message = years === 1 ? "a year ago" : `${years} years ago`
    } else if (months > 0 && years < 1) {
      message = months === 1 ? "a month ago" : `${months} months ago`
    } else if (weeks > 0 && weeks < 4 && months === 0) {
      message = weeks === 1 ? "a week ago" : `${weeks} weeks ago`
    } else if (weeks === 0 && days > 0) {
      message = days === 1 ? "a day ago" : `${days} days ago`
    } else if (days > 0 && hours > 0 && hours < 24) {
      message = hours === 1 ? "an hour ago" : `${hours} hours ago`
    } else if (minutes > 0 && minutes < 60 && hours < 1) {
      message = minutes === 1 ? "a minute ago" : `${minutes} minutes ago`
    } else if (seconds > 0 && seconds < 60 * 60 && minutes < 60) {
      message = seconds === 1 ? "a second ago" : `${seconds} seconds ago`
    } else {
      message = ""
    }
  }

  return message
}
