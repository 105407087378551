import axios from "axios"
import * as url from "./url_helper"
import store from "store"
import { notification } from "antd"
import { logoutUser } from "store/actions"

// import accessToken from "./jwt-token-access/accessToken"

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL
// const INTEGERATION_URL = process.env.REACT_APP_INTEGERATION_URL
const INTEGERATION_URL = "https://azarusaccounting.com/solidarity/api"

const username = "solidarity@comp.it"
const password = "123456"

// const IntegrationToken = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

// for basic system
const axiosApi = axios.create({
  baseURL: API_URL,
})

// for integration system
const integrationAxiosAPI = axios.create({
  baseURL: INTEGERATION_URL,
})

// for basic system
axiosApi.interceptors.request.use(
  (config) => {
    // check if there is an access token in localstorage
    const accessToken = localStorage.getItem("accessToken")
    const lang = localStorage.getItem("i18nextLng")
    if (lang) {
      config.headers["Accept-Language"] = lang
    } else {
      config.headers["Accept-Language"] = "en"
    }
    if (accessToken !== null) {
      config.headers["Authorization"] = `Bearer ${
        JSON.parse(accessToken).access_token
      }`
    } else {
      config.headers["Authorization"] = null
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// integrationAxiosAPI.interceptors.request.use(
//   async (config) => {
//     const basicAuthCredentials = window.btoa(username + ":" + password)
//     config.headers.common["Authorization"] = "Basic " + basicAuthCredentials
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const accessToken = localStorage.getItem("accessToken")
    const authUser = localStorage.getItem("authUser")
    if (
      error?.response?.status === 401 &&
      error?.response.statusText === "Unauthorized" &&
      (accessToken || authUser)
    ) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("accessToken")
      window.location.reload()
    } else if (error.response.status === 500) {
      notification.error({
        message: "Error",
        description: "an server error",
      })
    }

    return Promise.reject(error)
  }
)
export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}

export const request = ({ url, method, data, params, headers }) => {
  return new Promise(async (resolve, reject) => {
    const axios_obj = {
      url,
      method,
      ...(data && {
        data,
      }),
      ...(params && {
        params: {
          ...params,
        },
      }),
      headers: {
        ...(headers && {
          ...headers,
        }),
      },
    }
    try {
      const res = await axiosApi(axios_obj)
      resolve(res.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const integrationRequest = ({ url, method, data, params, headers }) => {
  console.log("url", url)
  console.log("url", method)
  return new Promise(async (resolve, reject) => {
    const axios_obj = {
      url,
      method,

      ...(data && {
        data,
      }),
      // ...(params && {
      //   params: {
      //     ...params,
      //   },
      // }),

      // headers: {
      //   ...(headers && {
      //     ...headers,
      //   }),
      // },
      // withCredentials: true,
      // headers: {
      //   Accept: "*/*",
      //   "Content-Type": "application/x-www-form-urlencoded",
      //   // Authorization: `Basic Auth`,
      //   // Authorization: `Basic window.btoa(username + ":" + password)`,
      // },
      headers: {
        Accept: "application/json",
        "Accept-Language": "en_US",
        // "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${window.btoa(username + ":" + password)}`,
      },
      // auth: {
      //   username: "solidarity@comp.it",
      //   password: "123456",
      // },
    }
    try {
      const res = await integrationAxiosAPI(axios_obj)
      resolve(res.data)
    } catch (e) {
      reject(e)
    }
  })
}
