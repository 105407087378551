import React, { Fragment, useState } from "react"

import { useLocation, useHistory } from "react-router-dom"
import { DeleteModal, ActivationModal } from "../Modals"

// custom hook
import useModuleActions from "hooks/useModuleActions"
// redux
import { useSelector } from "react-redux"

// utils
import {
  beneficaryListModuleId,
  claimFormModuleId,
  eventManagerModuleID,
  policyModuleId,
  predefinedModuleID,
  productionEventsId,
} from "utils/modulesIds"
import PremiumModal from "../Modals/PremiumModal"
import {
  financePaths,
  productionPaths,
  stakeholderPaths,
} from "common/data/routePaths"
import SpecialNotesModal from "../Modals/SpecialNotesModal"
import {
  memberDependencies,
  memberStatus,
  modalsStates,
  policyStatusSlugs,
} from "common/data"
import PremiumValidationModal from "components/TP/Beneficiary/PremiumValidationModal"
import { SingleRowAction } from "."
import SelectContractMemberModal from "components/TP/Policies/SelectContractMemberModal"
import { usePageType } from "hooks"
import SaveConfirmationModal from "../Modals/SaveConfirmationModal"

const RowActions = ({
  cellProps,
  cellProps: {
    row: { original: row },
  },
  onDelete,
  onActivate,
  // onDeactivate,
  onClickUpdate,
  onClickView,
  onClickStatus,
  onClickLog,
  onClickDependency,
  onClickCopy,
  onClickPrint,
  subModuleId,
  onPolicyIssueingClick,
  onChangePremiumClick,
  removeUpdatePermission,
  onTaskAssignToMeClick,
  children,
}) => {
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname

  const { isViewPage } = usePageType()
  //extract the  actions permissions from the custom hook
  const {
    isUpdatePermission,
    isSoftDeletePermission,
    isActivatePermission,
    isLogFilePermission,
    isDeactivatePermission,
    isBlackListPermission,
    isWhiteListPermission,
    isUserActivityPermission,
    isViewDependencyPermission,
    isCopyPermission,
    isViewPermission,
    isBeneficaryBenefitPermission,
    isBeneficaryViewPremiumPermission,
    isStandardCodePermission,
    isViewProcessingPermission,
    isProductAddPolicyPermission,
    isPolicyAddBeneficiaryPermission,
    isBeneficiarySpecialNotesPermission,
    isPolicyRenewalPermission,
    isViewEndorsementPermission,
    isBatchUploadPermission,
    isPrintPermission,
    isPolicyIssueingPermission,
    isProductChangPremiumPermission,
    isPrintInvoicePermission,
  } = useModuleActions(subModuleId && subModuleId)

  // get the module id form global state

  const moduleId = useSelector((state) => state?.Module?.actions?.id)

  const isPredefinedModule = moduleId === predefinedModuleID

  // states

  // to toggle the delete modal
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  // to toggle the delete modal
  const [isModal, setIsModal] = useState(false)

  // to toggle the activation modal
  const [isActivationModal, setIsActivationModal] = useState(false)
  // to toggle the premium modal
  const [isPremiumModal, setIsPremiumModal] = useState(false)
  // to toggle the validate premium modal
  const [isValidatePremiumModal, setIsValidatePremiumModal] = useState(false)

  // to toggle the special Notes modal
  const [isNotesModal, setIsNotesModal] = useState(false)

  const [isRenewedPolicyConfim, setIsRenewedPolicyConfim] = useState(false)
  // open delete modal
  const onClickDelete = () => {
    setIsDeleteModal(true)
  }

  // open activation modal
  const onClickActivate = () => {
    setIsActivationModal(true)
  }

  // open Premium modal
  const onClickViewPremium = () => {
    setIsPremiumModal(true)
  }

  const onClickSpeicalNotes = () => {
    setIsNotesModal(true)
  }

  return (
    !isViewPage && (
      <Fragment>
        <div className="d-flex gap-2">
          {/* View icon */}

          {isViewPermission && (
            <SingleRowAction
              onClick={onClickView}
              iconClass="mdi mdi-eye"
              actionName={isViewPermission?.name || "View"}
              record={row}
            />
          )}

          {/* Update Permission */}
          {isUpdatePermission && !removeUpdatePermission && onClickUpdate && (
            <SingleRowAction
              onClick={onClickUpdate}
              color="text-success"
              iconClass="mdi mdi-pencil"
              actionName={isUpdatePermission?.name}
              record={row}
            />
          )}

          {/* Deactivate Icon */}

          {isDeactivatePermission &&
            row?.is_active === 1 &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) && (
              <SingleRowAction
                onClick={onClickActivate}
                color="text-info"
                iconClass="mdi mdi-checkbox-marked-circle"
                actionName={isDeactivatePermission?.name}
                record={row}
              />
            )}

          {/* Activate Icon */}

          {isActivatePermission && row?.is_active === 0 && (
            <SingleRowAction
              onClick={onClickActivate}
              color="text-danger"
              iconClass="mdi mdi-checkbox-marked-circle"
              actionName={isActivatePermission?.name}
              record={row}
            />
          )}

          {/* Delete Icon */}

          {isSoftDeletePermission &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) &&
            // hide the delete icon in event manger and production events module when is_executed === 1
            (moduleId === eventManagerModuleID ||
            moduleId === productionEventsId
              ? !row?.is_executed
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickDelete}
                color="text-danger"
                iconClass="mdi mdi mdi-close"
                actionName={isSoftDeletePermission?.name}
                record={row}
              />
            )}

          {/* log history icon */}

          {isLogFilePermission && moduleId !== claimFormModuleId && (
            <SingleRowAction
              onClick={onClickLog}
              // color="text-danger"
              iconClass="mdi mdi-history"
              actionName={isLogFilePermission?.name}
              record={row}
            />
          )}

          {/* Print Permission */}
          {/* For Claim Based on Permission level 2 */}
          {/* For Beneficiary Without Permission level 2 (will be show for all active and parent beneficiaries ) */}
          {/* {(isPrintPermission ||
            (moduleId === beneficaryListModuleId &&
            row?.is_active &&
            !row?.group
              ? true
              : false)) && ( */}
          {isPrintPermission && moduleId !== claimFormModuleId && (
            <SingleRowAction
              onClick={onClickPrint}
              // color="text-danger"
              iconClass="mdi mdi-cloud-print-outline"
              actionName={isPrintPermission?.name || "print contract"}
              record={row}
            />
          )}

          {/* User Activity  icon */}

          {isUserActivityPermission && (
            <SingleRowAction
              to={`/users/${row?.id}/activity`}
              target="_blank"
              iconClass="mdi mdi-account-search"
              actionName={isUserActivityPermission?.name}
              record={row}
            />
          )}

          {/* Black List icon */}

          {isBlackListPermission &&
            (row?.status?.id === 116 || !row?.status) &&
            // hide in the beneficiary is he is not active
            (moduleId === beneficaryListModuleId
              ? row?.is_active
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickStatus}
                iconClass="mdi mdi-tooltip-remove"
                actionName={isBlackListPermission?.name}
                record={row}
              />
            )}

          {/* White List icon */}

          {isWhiteListPermission &&
            row?.status?.id === 115 &&
            // hide in the beneficiary is he is not active
            (moduleId === beneficaryListModuleId
              ? row?.is_active
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickStatus}
                color="text-danger"
                iconClass="mdi mdi-tooltip-check"
                actionName={isWhiteListPermission?.name}
                record={row}
              />
            )}

          {/* Dependency (Sub Beneficary) icon (show for principel beneficary onle) */}

          {isViewDependencyPermission &&
            row?.dependency?.slug === memberDependencies.principle.slug &&
            pathname !== financePaths.collections &&
            pathname !== stakeholderPaths.beneficiaryList && (
              <SingleRowAction
                onClick={onClickDependency}
                iconClass="mdi mdi-account-group"
                actionName={isViewDependencyPermission?.name}
                record={row}
              />
            )}

          {/*Beneficary View Premium icon */}

          {isBeneficaryViewPremiumPermission && row?.is_active === 1 && (
            <SingleRowAction
              onClick={onClickViewPremium}
              iconClass="mdi mdi-calculator-variant-outline"
              actionName={isBeneficaryViewPremiumPermission?.name}
              record={row}
            />
          )}

          {/*Beneficary View Endorsement icon (hide it when member status is InActive) */}

          {isViewEndorsementPermission &&
            row?.status?.slug !== memberStatus.inActive.slug && (
              <SingleRowAction
                to={
                  row?.policy
                    ? `/policy-endorsement/${row?.id}/${row?.policy?.id}`
                    : `/policy-endorsement/${row?.id}`
                }
                target="_blank"
                iconClass="mdi mdi-alpha-e-box-outline"
                actionName={isViewEndorsementPermission?.name}
                record={row}
              />
            )}

          {/*Beneficary Special Notes  icon */}

          {isBeneficiarySpecialNotesPermission && (
            <SingleRowAction
              onClick={onClickSpeicalNotes}
              iconClass="mdi mdi-android-messages"
              actionName={isBeneficiarySpecialNotesPermission?.name}
              record={row}
            />
          )}

          {/* Copy (clone) icon */}

          {isCopyPermission && moduleId !== policyModuleId && (
            <SingleRowAction
              onClick={onClickCopy}
              iconClass="mdi mdi-content-copy"
              actionName={isCopyPermission?.name}
              record={row}
            />
          )}

          {/* Batch Upload icon (appear here for just policy module) */}

          {isBatchUploadPermission && moduleId === policyModuleId && (
            <SingleRowAction
              to={`beneficiaries/${row?.id}/import`}
              iconClass="mdi mdi-cloud-upload"
              actionName={isBatchUploadPermission?.name}
              record={row}
            />
          )}

          {/* Product Add Policy icon */}

          {isProductAddPolicyPermission && row?.is_active === 1 && (
            <SingleRowAction
              to={{
                pathname: `${productionPaths.policy}/add`,
                state: {
                  product_id: row?.id,
                  payer_id: row?.payer?.id,
                },
              }}
              iconClass="mdi mdi-shield-plus"
              actionName={isProductAddPolicyPermission?.name}
              record={row}
            />
          )}

          {/* Policy Add Beneficiary icon */}

          {isPolicyAddBeneficiaryPermission && (
            <SingleRowAction
              to={`${stakeholderPaths.beneficiaryList}/${row?.id}/add`}
              iconClass="mdi mdi-account-plus"
              actionName={isPolicyAddBeneficiaryPermission?.name}
              record={row}
            />
          )}

          {/* Policy Renewal icon */}

          {isPolicyRenewalPermission &&
            row?.status?.slug !== policyStatusSlugs.inProgress &&
            row?.status?.slug !== policyStatusSlugs.rejected && (
              <Fragment>
                {/* if the policy already renewed show button */}
                {/* if not renewed show Link */}
                {row?.is_renewed ? (
                  <SingleRowAction
                    onClick={() => {
                      setIsRenewedPolicyConfim(true)
                    }}
                    iconClass="mdi mdi-alpha-r-box"
                    actionName={isPolicyRenewalPermission?.name}
                    record={row}
                  />
                ) : (
                  <SingleRowAction
                    to={{
                      // pathname: `${stakeholderPaths.beneficiaryList}/renewal/${row?.id}`,
                      pathname: `${productionPaths.policy}/renewal/${row?.id}`,
                      state: { policy: row },
                    }}
                    iconClass="mdi mdi-alpha-r-box"
                    actionName={isPolicyRenewalPermission?.name}
                    record={row}
                  />
                )}
              </Fragment>
            )}

          {/* Policy Issueing icon */}

          {isPolicyIssueingPermission && row?.is_active === 0 && (
            <SingleRowAction
              onClick={onPolicyIssueingClick}
              iconClass="mdi mdi-briefcase-check"
              actionName={isPolicyIssueingPermission?.name}
              record={row}
            />
          )}
          {/* Product Change Premium */}
          {isProductChangPremiumPermission && (
            <SingleRowAction
              onClick={onChangePremiumClick}
              iconClass="mdi mdi-briefcase-check"
              actionName={isProductChangPremiumPermission?.name}
              record={row}
            />
          )}

          {/* Beneficiary Print Invoice icon (for parent Memebers only) */}

          {isPrintInvoicePermission && !row.group && (
            <SingleRowAction
              to={`${financePaths.invoice}/print/${row?.id}`}
              iconClass="mdi mdi-receipt"
              target="_blank"
              actionName={isPrintInvoicePermission?.name}
              record={row}
            />
          )}

          {pathname === productionPaths.policy &&
            row?.status?.slug !== policyStatusSlugs.inProgress && (
              <SingleRowAction
                onClick={() => setIsModal(modalsStates.policyPrintContract)}
                iconClass="mdi mdi-cloud-print-outline"
                actionName="Print Contract"
                record={row}
              />
            )}
          {row?.users?.length === 0 && onTaskAssignToMeClick && (
            <SingleRowAction
              onClick={onTaskAssignToMeClick}
              iconClass={"bx bx-task"}
              actionName={"Assign To Me"}
              record={row}
            />
          )}

          {children ? children : null}
        </div>

        <DeleteModal
          show={isDeleteModal}
          onDeleteClick={() => {
            onDelete()
            setIsDeleteModal(false)
          }}
          onCloseClick={() => setIsDeleteModal(false)}
        />

        <ActivationModal
          show={isActivationModal}
          record={row}
          setIsActivationModal={setIsActivationModal}
          onConfirmClick={() => {
            onActivate()
            setIsActivationModal(false)
          }}
          onCloseClick={() => setIsActivationModal(false)}
        />

        {isPremiumModal && (
          <PremiumModal
            isPremiumModal={isPremiumModal}
            setIsPremiumModal={setIsPremiumModal}
            // beneficaryId={recordId}
            beneficaryId={row?.id}
          />
        )}
        {isNotesModal && (
          <SpecialNotesModal
            show={isNotesModal}
            onCloseClick={() => setIsNotesModal(false)}
            beneficaryId={row?.id}
            beneficary={row}
          />
        )}

        {isValidatePremiumModal && (
          <PremiumValidationModal
            isModal={isValidatePremiumModal}
            setIsModal={setIsValidatePremiumModal}
            beneficiary={row}
          />
        )}
        {isModal === modalsStates.policyPrintContract && (
          <SelectContractMemberModal
            isModal={
              isModal === modalsStates.policyPrintContract ? true : false
            }
            setIsModal={setIsModal}
            policyId={row?.id}
          />
        )}

        {isRenewedPolicyConfim && (
          <SaveConfirmationModal
            show={isRenewedPolicyConfim}
            onCloseClick={() => setIsRenewedPolicyConfim(false)}
            onConfirmClick={() =>
              history.push({
                // pathname: `${stakeholderPaths.beneficiaryList}/renewal/${row?.id}`,
                pathname: `${productionPaths.policy}/renewal/${row?.id}`,
                state: { policy: row },
              })
            }
            confirmationMessage="This Policy Already Renewed"
            modalTitle="Policy Renewal"
            saveBtnTitle="Force Renewal"
          />
        )}
      </Fragment>
    )
  )
}

export default RowActions
