/*
This is Hook is used to check if the 
user want to leave the tab without saving her/his changes in this tab
*/

import { isEqual } from "lodash"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { toggleChange } from "store/actions"

const useTabLeave = (validation) => {
  const dispatch = useDispatch()

  // if any of the initial values array of length 1 empty it
  const modifiedInitialValues = Object.fromEntries(
    // convert initial values to array, map each key/value pair into another pair
    // and then fromEntries gives back the object
    Object.entries(validation.initialValues).map(([key, value]) => {
      return Array.isArray(value) && value?.length === 1
        ? [key, []]
        : [key, value]
    })
  )
  // if anyof the values is array of length 1 empty it

  const modifiedValues = Object.fromEntries(
    // convert values to array, map each key/value pair into another pair
    // and then fromEntries gives back the object
    Object.entries(validation.values).map(([key, value]) => {
      return Array.isArray(value) && value?.length === 1
        ? [key, []]
        : [key, value]
    })
  )

  // useEffect(() => {
  //   if (isEqual(validation.initialValues, validation.values)) {
  //     dispatch(toggleChange({ value: false }))
  //   } else {
  //     dispatch(toggleChange({ value: true }))
  //   }
  // }, [validation.values])

  useEffect(() => {
    if (isEqual(validation.initialValues, validation.values)) {
      dispatch(toggleChange({ value: false }))
    } else {
      dispatch(toggleChange({ value: true }))
    }
  }, [JSON.stringify(validation.values)])

  // useEffect(() => {
  //   if (isEqual(modifiedInitialValues, modifiedValues)) {
  //     dispatch(toggleChange({ value: false }))
  //   } else {
  //     dispatch(toggleChange({ value: true }))
  //   }
  // }, [])
}

export default useTabLeave
