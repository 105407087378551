import PropTypes from "prop-types"
import React from "react"
import { Modal } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const DropdownChangeModal = ({ show, onCloseClick, t }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      {/* Modal Header */}

      <div className="modal-header">
        {/* Modal Header Title */}

        <h5 className="modal-title mt-0 text-danger" id="myModalLabel">
          Warning!!!
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <p className="text-muted font-size-16 mb-4 text-left">
          There Are some data will be changing after select another option!
        </p>
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-center mb-0">
          {/* <button
            type="button"
            className="btn btn-light"
            onClick={onCloseClick}
          >
            Close
          </button> */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={onCloseClick}
          >
            Understood!
          </button>
        </div>
      </div>
    </Modal>
  )
}

DropdownChangeModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  //   isActivating: PropTypes.bool,
}
export default withTranslation()(DropdownChangeModal)
