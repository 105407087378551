import { isEqual, omit, pick } from "lodash"

export function findDuplicatedItems(arr, keys) {
  let duplicated = []
  const pickedArray =
    arr &&
    Array.isArray(arr) &&
    arr.map((item) => {
      const pickedItem = pick(item, [...keys, "id"])
      return pickedItem
    })

  for (let i = 0; i < pickedArray.length - 1; i++) {
    for (let j = i + 1; j < pickedArray.length; j++) {
      if (isEqual(omit(pickedArray[i], ["id"]), omit(pickedArray[j], ["id"]))) {
        if (!duplicated.find((item) => item.id === pickedArray[i].id)) {
          duplicated.push({ index: i + 1, ...pickedArray[i] })
        }
        if (!duplicated.find((item) => item.id === pickedArray[j].id)) {
          duplicated.push({ index: j + 1, ...pickedArray[j] })
        }
      }
    }
  }
  return duplicated
}
