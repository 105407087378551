import { request } from "../../api_helper"
import * as url from "../../url_helper"
/*

Co Participation
*/

// show Product
//url/{id}

export const showProduct = productId =>
  request({
    url: `${url.PRODUCTS}/${productId}/${url.COPARTICIPATION}`,
    method: "GET",
  })
// show Product Benefits
//url/{id}

export const showProductBenefits = productId =>
  request({
    url: `${url.PRODUCTS}/${productId}/benefits`,
    method: "GET",
  })

// update Product
//url/{id}

export const updateProduct = data =>
  request({
    url: `${url.PRODUCTS}/${data.id}/${url.COPARTICIPATION}`,
    method: "PATCH",
    data,
  })
