import React, { useState } from "react"
import { Input, InputGroup, Label } from "reactstrap"
import FormInputError from "./FormInputError"
import { NumericFormat } from "react-number-format"
import { usePageType } from "hooks"

const FormControll = ({
  id,
  name,
  type,
  value,
  min,
  max,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  error,
  wrapperClassNames,
  children,
  getOptions,
  mappingValue,
  mappingLabel,
  onChange,
  validation,
  includeNumricFormat,
  readOnly,
  horizontalLayout,
  noLabel,
  allowNegative,
  disableViewPage = false,

  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const label =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const { invalid } = rest

  // to know if the page link has a view word or not
  const { isViewPage: isInViewPage } = usePageType()
  const isViewPage = isInViewPage && !disableViewPage

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <Label
          htmlFor={id}
          className={`text-capitalize ${
            inputField?.is_required ? "required-label" : ""
          } ${labelClass ? labelClass : ""}`}
        >
          {label}
        </Label>
      )}
      <div
        className={horizontalLayout && inputClass}
        // style={{ height: type !== "textarea" && "40px" }}
      >
        {includeNumricFormat ? (
          <NumericFormat
            id={id}
            name={name}
            min={min ? min : undefined}
            max={max ? max : undefined}
            value={value && value}
            placeholder={placeholder}
            thousandSeparator=","
            valueIsNumericString={true}
            allowNegative={allowNegative ? allowNegative : false}
            {...rest}
            onValueChange={(values) => {
              const { value, floatValue } = values
              // validation.setFieldValue(`${name}`, value)
              // onChange(`${name}`, value)
              if (onChange) {
                onChange(`${name}`, floatValue)
              }
            }}
            invalid={isViewPage || readOnly ? false : invalid}
            customInput={Input}
            // for decimal sperator
            decimalScale={2}
            fixedDecimalScale
            // for readonly component
            isAllowed={() => (isViewPage || readOnly ? false : true)}
            displayType={isViewPage || readOnly ? "text" : "input"}
            renderText={(value) => (
              <Input
                value={value}
                onChange={undefined}
                className="bg-secondary bg-opacity-10"
                readOnly={true}
              />
            )}
          />
        ) : type === "password" ? (
          <InputGroup>
            <Input
              id={id}
              name={name}
              type={showPassword ? "text" : "password"}
              value={value && value}
              placeholder={placeholder}
              className={`${inputClass ? inputClass : ""} ${
                isViewPage ? "border-0" : ""
              } ${readOnly ? "bg-secondary bg-opacity-10" : ""}`}
              {...rest}
              invalid={isViewPage || readOnly ? false : invalid}
              onChange={
                onChange && !isViewPage && !readOnly ? onChange : () => {}
              }
              // in view page make the input read only or if is readonly prop
              readOnly={isViewPage || readOnly}
            />

            {!isViewPage ? (
              <div
                className="input-group-text"
                onClick={() => setShowPassword((showPassword) => !showPassword)}
              >
                {showPassword ? (
                  <i className="mdi mdi-eye-off-outline font-size-14"></i>
                ) : (
                  <i className="mdi mdi-eye-outline font-size-14"></i>
                )}
              </div>
            ) : null}
            {invalid && !isViewPage && !readOnly ? (
              <FormInputError error={error} />
            ) : null}
          </InputGroup>
        ) : (
          <Input
            id={id}
            min={type === "number" && min ? min : undefined}
            max={type === "number" && max ? max : undefined}
            name={name}
            type={type}
            value={value && value}
            placeholder={placeholder}
            className={`${type !== "textarea" && "h-100"} ${
              inputClass ? inputClass : ""
            } ${type === "number" ? "custom-number-input" : ""} ${
              isViewPage ? "border-0" : ""
            } ${readOnly ? "bg-secondary bg-opacity-10" : ""}`}
            {...rest}
            invalid={isViewPage || readOnly ? false : invalid}
            onChange={
              onChange && !isViewPage && !readOnly ? onChange : () => {}
            }
            // in view page make the input read only or if is readonly prop
            readOnly={isViewPage || readOnly}
          />
        )}

        {/* Error Message */}
        {invalid && !isViewPage && !readOnly ? (
          <FormInputError error={error} />
        ) : null}
      </div>
    </div>
  )
}

export default FormControll
