/*
This is Hook is used to Extract the permissions 
allowed on each Module for the logged in user
*/

import React from "react"
import { useSelector } from "react-redux"
import {
  eventManagerModuleID,
  blacklistedReportModuleId,
  overDueReportModuleId,
  dueToExpiryReportModuleId,
  policyModuleId,
  beneficaryListModuleId,
  beneficaryModuleId,
} from "utils/modulesIds"

const useModuleActions = (subModuleId) => {
  const { actions, subModule, moduleId } = useSelector((state) => ({
    moduleId: state.Module?.id,
    actions: state.Module.actions,
    subModule: state.Module?.subModules?.find(
      (item) => item.id === subModuleId
    ),
  }))

  const extractSpecificAction = (module, actionId) => {
    if (
      module &&
      module.actions &&
      module.actions.find((action) => action.id === actionId)
    )
      // return true
      return module.actions.find((action) => action.id === actionId)
    else return false
  }

  // remove add permission in event manage page
  const isAddPermission =
    moduleId !== eventManagerModuleID &&
    extractSpecificAction(subModuleId ? subModule : actions, 1)
  const isUpdatePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    2
  )
  const isSoftDeletePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    3
  )
  // disable this premission in policy module

  const isActivatePermission =
    moduleId === policyModuleId
      ? false
      : extractSpecificAction(subModuleId ? subModule : actions, 4)

  const isLogFilePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    5
  )
  const isTableFilterPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    6
  )
  const isTableViewPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    7
  )
  // hide it for the subModules
  // const isViewPermission = !subModuleId
  //   ? extractSpecificAction(actions, 8)
  //   : false
  const isViewPermission =
    !subModuleId || subModuleId === beneficaryModuleId
      ? extractSpecificAction(subModuleId ? subModule : actions, 8)
      : false

  // give export permission to blacklisted report module by default

  const isTableExportPermission =
    moduleId === blacklistedReportModuleId ||
    moduleId === overDueReportModuleId ||
    moduleId === dueToExpiryReportModuleId
      ? true
      : extractSpecificAction(subModuleId ? subModule : actions, 9)

  // disable this premission in policy module
  const isDeactivatePermission =
    moduleId === policyModuleId
      ? false
      : extractSpecificAction(subModuleId ? subModule : actions, 10)
  const isBlackListPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    16
  )
  const isWhiteListPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    17
  )
  const isUserActivityPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    18
  )

  const isViewDependencyPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    19
  )
  const isCopyPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    20
  )

  const isBatchUploadPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    21
  )

  const isBeneficaryBenefitPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    23
  )
  const isBeneficaryViewPremiumPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    23
  )
  const isStandardCodePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    24
  )
  const isViewProcessingPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    25
  )

  const isProductAddPolicyPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    27
  )
  const isPolicyAddBeneficiaryPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    28
  )
  const isBeneficiarySpecialNotesPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    29
  )
  const isPolicyRenewalPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    30
  )
  const isViewEndorsementPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    31
  )
  const isPrintPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    35
  )
  const isPolicyIssueingPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    36
  )
  const isProductChangPremiumPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    37
  )

  // hide it in the beneficiary list module id
  const isPrintInvoicePermission =
    moduleId !== beneficaryListModuleId
      ? extractSpecificAction(subModuleId ? subModule : actions, 38)
      : false
  const isForceSavePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    39
  )
  const isTaskAssignToMePermissions = extractSpecificAction(
    subModuleId ? subModule : actions,
    40
  )
  const isTaskReAssignPermissions = extractSpecificAction(
    subModuleId ? subModule : actions,
    41
  )
  const isShowDepartmentsTasksPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    43
  )

  return {
    isAddPermission,
    isUpdatePermission,
    isSoftDeletePermission,
    isActivatePermission,
    isDeactivatePermission,
    isLogFilePermission,
    isTableFilterPermission,
    isTableViewPermission,
    isViewPermission,
    isTableExportPermission,
    isBlackListPermission,
    isWhiteListPermission,
    isUserActivityPermission,
    isViewDependencyPermission,
    isCopyPermission,
    isBatchUploadPermission,
    isBeneficaryViewPremiumPermission,
    isBeneficaryBenefitPermission,
    isStandardCodePermission,
    isViewProcessingPermission,
    isProductAddPolicyPermission,
    isPolicyAddBeneficiaryPermission,
    isBeneficiarySpecialNotesPermission,
    isPolicyRenewalPermission,
    isViewEndorsementPermission,
    isPrintPermission,
    isPolicyIssueingPermission,
    isProductChangPremiumPermission,
    isPrintInvoicePermission,
    isForceSavePermission,
    isTaskAssignToMePermissions,
    isTaskReAssignPermissions,
    isShowDepartmentsTasksPermission
  }
}

export default useModuleActions
