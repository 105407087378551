import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Modal } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
import CustomButton from "../General/CustomButton"
import { buttonsTitles } from "common/data"
import { useModuleActions } from "hooks"
const FormModal = ({
  show,
  onSaveClick,
  onCloseClick,
  isSaving,
  children,
  t,
  modalTitle,
  modalSize,
  noFooter,
  notCentered,
  includeComputeButton,
  onComputeClick,
  hideSaveButton,
  saveButtonTitle,
  saveButtonBG,
  modalClassNames,
  scrollable,
  noKeyboard,
  noCloseIcon,
  scrollWhenError,
  error,
  additionalFooterButtons,
  disableTooltip,
  disableSave,
  fullscreen = false,
  module,
  forceSave,
}) => {
  useEffect(() => {
    if (error && scrollWhenError) {
      // formRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
      document
        .querySelector(".modal")
        .scrollTo({ behavior: "smooth", top: 0, left: 0 })
    }
  }, [error, scrollWhenError])

  const { isForceSavePermission } = useModuleActions(module && module.id)

  return (
    <Modal
      size={modalSize ? modalSize : "md"}
      isOpen={show}
      scrollable={scrollable ? scrollable : false}
      toggle={onCloseClick}
      centered={notCentered ? false : true}
      backdrop="static"
      className={modalClassNames && modalClassNames}
      keyboard={noKeyboard ? false : true}
      fullscreen={fullscreen}
    >
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          {modalTitle}
        </h5>

        {/* close button on the top right section */}
        {!noCloseIcon ? (
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        ) : null}
      </div>

      {/* Modal Body */}
      <div className="modal-body">{children}</div>

      {/* Modal Footer */}
      {!noFooter && (
        <div className="modal-footer">
          {additionalFooterButtons && additionalFooterButtons}

          {hideSaveButton ? null : (
            <CustomButton
              type="button"
              className={`btn ${
                saveButtonBG ? `btn-${saveButtonBG}` : "btn-success"
              }`}
              onClick={onSaveClick}
              disabled={isSaving || disableSave ? true : false}
              disabledTooltip={disableTooltip ? disableTooltip : ""}
              title={
                saveButtonTitle ? t(`${saveButtonTitle}`) : buttonsTitles.save
              }
              isSaving={isSaving}
            />
          )}

          {includeComputeButton ? (
            <CustomButton
              type="button"
              className="btn btn-info"
              onClick={onComputeClick}
              disabled={isSaving || disableSave ? true : false}
              disabledTooltip={disableTooltip ? disableTooltip : ""}
              title={buttonsTitles.compute}
              isSaving={isSaving}
            />
          ) : null}

          {isForceSavePermission && forceSave ? (
            <CustomButton
              type="submit"
              disabled={isSaving || disableSave ? true : false}
              className="btn btn-primary"
              title={isForceSavePermission?.name}
              isSaving={isSaving}
            />
          ) : null}
        </div>
      )}
    </Modal>
  )
}

FormModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default withTranslation()(FormModal)
