import { TableSkeleton } from "components/Common"
import React from "react"
import { NoRecords } from "."
import { isString } from "lodash"

const StickyHeaderTable = ({ isLoading, columns, noData, children }) => {
  return (
    <div
      className="table-responsive react-table mt-3"
      style={{ overflow: "auto", display: "block" }}
    >
      <table
        className="table table-hover"
        style={{
          position: "relative",
          height: isLoading && "550px",
        }}
      >
        <thead
          className="table-light table-nowrap sticky-header"
          id="__FPSC_ID_1_1683283486344"
        >
          <tr>
            {columns.map(column => (
              <th key={isString(column) ? column : column.id}>
                {isString(column) ? column : column.label}
              </th>
            ))}
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={columns?.length}>
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                  }}
                >
                  <TableSkeleton />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {noData ? (
              <tr>
                <td colSpan={columns.length + 2}>
                  <NoRecords />
                </td>
              </tr>
            ) : (
              children
            )}
          </tbody>
        )}
      </table>
    </div>
  )
}

export default StickyHeaderTable
