/*
parameters:
module : module object the contains actions Array | Type: Object

actionId: the ID of the action that we want to know if exists in permissions or not 
like (Add,Soft Delete,etc...)
| Type: Number
*/

export function extractSpecificAction(module, actionId) {
  if (
    module &&
    module.actions &&
    module.actions.find(action => action.id === actionId)
  )
    return true
  else return false
}
