// paths

import { productionPaths } from "common/data/routePaths"
import { lazy } from "react"
// Components
const Products = lazy(() => import("pages/Production/Products"))
const ProductTabs = lazy(() => import("pages/Production/Products/ProductTabs"))

const Policies = lazy(() => import("pages/Production/Policies"))
const PolicyTabs = lazy(() => import("pages/Production/Policies/PolicyTabs"))

const PolicyEndorsement = lazy(() =>
  import("pages/Production/PolicyEndorsement")
)
const EndoresmentPrint = lazy(() =>
  import("pages/Production/PolicyEndorsement/EndoresmentPrint/EndoresmentPrint")
)
// additional endorsement print
const AdditionalEndoresmentPrint = lazy(() =>
  import(
    "pages/Stakeholders/BeneficiaryList/AdditionalEndoresment/AdditionalEndoresmentPrint"
  )
)
const UploadBeneficiaries = lazy(() =>
  import("pages/Production/Policies/UploadBeneficiaries")
)
const ProductPdf = lazy(() =>
  import("pages/Production/Products/PDF/ProductPdf")
)

// Policy Renewal
const PolicyRenewal = lazy(() => import("pages/Production/PolicyRenewal"))

// beneficay benefits
// import BeneficaryBenefit from "pages/Production/Policies/SecondTab/BeneficaryBenefit"

// destructure production paths
const { products, policy } = productionPaths

export const Productions = [
  // products
  { path: products, component: Products },
  { path: `${products}/add`, component: ProductTabs },
  { path: `${products}/edit/:id`, component: ProductTabs },
  { path: `${products}/view/:id`, component: ProductTabs },
  // Policies
  { path: policy, component: Policies },
  { path: `${policy}/add`, component: PolicyTabs },
  { path: `${policy}/edit/:id`, component: PolicyTabs },
  { path: `${policy}/edit/:id/:memberId`, component: PolicyTabs },
  { path: `${policy}/view/:id`, component: PolicyTabs },
  { path: `${products}/print/:id`, component: ProductPdf },
  // batch Upload Beneficiary
  { path: "/beneficiaries/:policyId/import", component: UploadBeneficiaries },
  // policy endorsement
  // with policy ID (if beneficiary has policy Id)
  { path: "/policy-endorsement/:id/:policyId", component: PolicyEndorsement },
  // without policy ID (if beneficiary has no policy Id)
  { path: "/policy-endorsement/:id", component: PolicyEndorsement },
  {
    path: "/policy-endorsement/print/:id/:policyId",
    component: EndoresmentPrint,
  },
  {
    path: "/additional-endorsement/print/:id",
    component: AdditionalEndoresmentPrint,
  },

  { path: `${policy}/renewal/:policyId`, component: PolicyRenewal },
  // Beneficary Benefits
  // { path: "/beneficary/benefit/:id", component: BeneficaryBenefit },
]
