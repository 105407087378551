import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Modal } from "reactstrap"
import { logoutUser, refreshToken } from "store/actions"

const ExpirationModal = ({ show, onCloseClick }) => {
    const [timer, setTimer] = useState(30)
    const dispatch = useDispatch()
    const history = useHistory()
    let interval = ""

    useEffect(() => {
        setTimer(30)
        if (show) {
            interval = setInterval(() => {
                setTimer((prevValue) => prevValue - 1)
            }, 1000);

            // setTimeout(() => {
            //         onClose()
            // }, 30000)
        }
        return () => clearInterval(interval)

    }, [show])

    useEffect(() => {
        if (!timer && show) {
            onClose()
        }
    }, [timer])

    const onClose = () => {
        clearInterval(interval)
        onCloseClick()
        dispatch(logoutUser(history))
    }

    const getRefreshToken = () => {
        dispatch(refreshToken())
        clearInterval(interval)
        onCloseClick()
    }

    return (
        <Modal size="md" isOpen={show} toggle={onClose}>
            <div className="modal-header">
                {/* Modal Header Title */}
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Session Timeout
                </h5>

                {/* close button on the top right section */}
                <button
                    type="button"
                    onClick={onCloseClick}
                    className="btn-close position-absolute end-0 top-0 m-3"
                ></button>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
                <p><b>Session will be expired after 30 seconds.</b></p>
                <p>Please choose to stay signed in or to logoff, Otherwise, you will logged off automatically.</p>
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={getRefreshToken}
                    disabled={!timer}
                >
                    Stay Logged In ({timer})
                </button>
            </div>
        </Modal>
    )
}

export default ExpirationModal