import { call, put, takeEvery } from "redux-saga/effects"

// User Redux States
import { GET_NOTIFICATIONS } from "./actionTypes"
import { getNotificationsSuccess, getNotificationsFail } from "./actions"

// import helpers functions

import { getAllNotifications } from "helpers/General/notifications_helpers"
import { NotificationMessage, apiErrorrHandler } from "utils"

function* onGetNotifications() {
  try {
    const response = yield call(getAllNotifications, {
      per_page: 1000,
      is_read: 0,
      user_id: JSON.parse(localStorage.getItem("authUser")).id,
    })
    const { items: notifications } = response
    console.log("response", response)
    yield put(getNotificationsSuccess(notifications))
  } catch (error) {
    console.log("error", error)
    const errorMessage = apiErrorrHandler(error)
    NotificationMessage("error", errorMessage)
    yield put(getNotificationsFail(error))
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, onGetNotifications)
}

export default notificationsSaga
