import { call, put, takeEvery } from "redux-saga/effects"

// User Redux States
import { GET_ACTIONS, GET_SECTION, GET_SUBMODULES } from "./actionTypes"
import {
  getActionsSuccess,
  getActionsFail,
  getSubmodulesSuccess,
  getSubmodulesFail,
  getSectionSuccess,
  getSectionFail,
} from "./actions"

// import helpers functions

import {
  getAllActions,
  getAllSubModules,
} from "../../../helpers/Permissions/permissions_helper"
import { apiErrorrHandler } from "utils"

function* onGetActions({ payload: moduleId }) {
  try {
    const response = yield call(getAllActions, moduleId)
    const { item: module } = response
    console.log("response", response)
    yield put(getActionsSuccess(module))
  } catch (error) {
    console.log("error", error)
    yield put(getActionsFail(error))
  }
}
function* onGetSubModules({ payload: moduleId }) {
  try {
    const response = yield call(getAllSubModules, moduleId)
    const { item } = response
    console.log("response", item)
    yield put(
      getSubmodulesSuccess({
        actions: item.actions,
        fields: item.fields,
        subModules: item.sub_modules,
        name: item?.title,
        id: item.id,
        has_workflow: item?.has_workflow,
      })
    )
  } catch (error) {
    console.log("error", error)
    const errorMessage = apiErrorrHandler(error)
    yield put(getSubmodulesFail(errorMessage))
  }
}

function* onGetSection({ payload: moduleId }) {
  try {
    const response = yield call(getAllSubModules, moduleId)
    const { item } = response
    console.log("response", item)
    yield put(getSectionSuccess(item))
  } catch (error) {
    console.log("error", error)
    const errorMessage = apiErrorrHandler(error)
    yield put(getSectionFail(errorMessage))
  }
}

function* moduleSaga() {
  yield takeEvery(GET_ACTIONS, onGetActions)
  yield takeEvery(GET_SUBMODULES, onGetSubModules)
  yield takeEvery(GET_SECTION, onGetSection)
}

export default moduleSaga
