import React, { Fragment } from "react"
import { TreeSelect } from "antd"
const { SHOW_PARENT } = TreeSelect
// const treeData = [
//   {
//     title: "Node1",
//     value: "0-0",
//     key: "0-0",
//     children: [
//       {
//         title: "Child Node1",
//         value: "0-0-0",
//         key: "0-0-0",
//       },
//     ],
//   },
//   {
//     title: "Node2",
//     value: "0-1",
//     key: "0-1",
//     children: [
//       {
//         title: "Child Node3",
//         value: "0-1-0",
//         key: "0-1-0",
//       },
//       {
//         title: "Child Node4",
//         value: "0-1-1",
//         key: "0-1-1",
//       },
//       {
//         title: "Child Node5",
//         value: "0-1-2",
//         key: "0-1-2",
//       },
//     ],
//   },
// ]

const CheckableSelectTree = ({
  label,
  id,
  value,
  onChange,
  treeData,
  placeholder,
}) => {
  // const [value, setValue] = useState([])
  // const onChange = newValue => {
  //   console.log(newValue)
  //   setValue(newValue)
  // }
  const tProps = {
    value,
    treeData,
    onChange,
    id,
    placeholder,
    bordered: false,
    showSearch: true,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    style: {
      width: "100%",
    },
  }
  return (
    <Fragment>
      <label className="form-label text-capitalize">{label}</label>
      <div className="form-control px-0 " style={{ paddingBlock: "2px" }}>
        <TreeSelect {...tProps} />
      </div>
    </Fragment>
  )
}
export default CheckableSelectTree
