import React from "react"
import { Modal, ModalBody, Spinner } from "reactstrap"

const LoadingModal = ({ isOpen }) => {
  return (
    <Modal size="md" backdrop="static" isOpen={isOpen} centered={true}>
      <ModalBody>
        <div className="d-flex align-items-center gap-4">
          <Spinner className="ms-2" color="info" />
          <h6 className="lh-base">
            Please wait while we validate your information.
            <br /> This process may take a few moments.
            <br /> Thank you for your patience.
          </h6>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LoadingModal
