import React, { useEffect, useState } from "react"
import {
  NotificationMessage,
  apiErrorrHandler,
  convertNullToString,
} from "utils"

const useFetchSingleRecord = (getDataFunc, recordId, params) => {
  const [results, setResults] = useState(null)
  const [loading, setLoading] = useState(false)

  const getData = async (id) => {
    try {
      setLoading(true)
      const res = await getDataFunc(id, params)
      setResults(convertNullToString(res.item))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  useEffect(() => {
    if (getData && recordId) {
      getData(recordId)
    }
  }, [recordId])

  return {
    loading,
    results,
  }
}

export default useFetchSingleRecord
