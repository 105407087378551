import PropTypes from "prop-types"
import React from "react"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"
// import logoDark from "../../assets/images/logo-dark.png"
// import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/ACES_COLORED.png"
import logoLightPng from "../../assets/images/ACES_WHITE.png"
import PearlLogo from "assets/images/logos/Pearl.png"
import { ConfigProvider, Skeleton } from "antd"
import { CompanyName } from "constants/TPA"

const Sidebar = (props) => {
  const { loading } = useSelector((state) => ({
    loading: state.Sidebar.loading,
  }))
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          {/* Skote Logo Dark */}

          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={PearlLogo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              {/* <img src={logoDark} alt="" height="17" /> */}
              <img src={PearlLogo} alt="" height="80" />
            </span>
          </Link>

          {/* Skote Logo Light */}

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              {/* <img src={logoLightSvg} alt="" height="22" /> */}
              <img src={PearlLogo} alt={`${CompanyName} Logo`} height="15" />
            </span>
            <span className="logo-lg d-flex justify-content-start">
              {/* <img src={logoLightPng} alt="" height="19" /> */}
              {/* <img src={logoLightPng} alt="" height="80" /> */}
              <img src={PearlLogo} alt={`${CompanyName} Logo`} height="70" />
            </span>
          </Link>
        </div>

        {/* Sidebar Links */}

        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>

        {/* Sidebar Background (which could be modified by the user) */}

        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
