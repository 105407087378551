// paths
import { networkPaths } from "common/data/routePaths"
import { lazy } from "react"
// Components
const NetworkList = lazy(() => import("pages/Networks/NetworkList"))
const NetworkForms = lazy(() =>
  import("pages/Networks/NetworkList/NetworkForms")
)

const { networks } = networkPaths

export const Networks = [
  { path: networks, component: NetworkList },
  { path: `${networks}/add`, component: NetworkForms },
  { path: `${networks}/edit/:id`, component: NetworkForms },
]
