import React, { Fragment, useEffect, useRef, useState } from "react"
import { Label, UncontrolledTooltip } from "reactstrap"
import AsyncSelect from "react-select/async"
import FormInputError from "./FormInputError"
import { apiErrorrHandler, isItemObject, NotificationMessage } from "utils"
import DropdownChangeModal from "../Modals/DropdownChangeModal"
import { compact, defaults, has, omit, pick, set } from "lodash"
import useSWR from "swr"
import { generalRequest } from "helpers/General/general_request"
import { usePageType } from "hooks"
import DropDownSkeleton from "./DropDownSkeleton"

const AsyncSearchSelect = ({
  id,
  name,
  value: propValue,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  options,
  onChange,
  customOnChange,
  disableSearch,
  readOnly,
  searchKey,
  searchParam,
  onBlur,
  invalid,
  error,
  getOptionsData,
  onSelectData,
  showPopupOnChange,
  urlAddOns,
  dynamicQuery,
  mappingLabel,
  mappingValue,
  wrapperClassNames,
  horizontalLayout,
  fixedMenu,
  multiple,
  noLabel,
  generateCustomLabels,
  hideSelectedOptions,
  recordId,
  onClear,
  disableSorting,
  formatOptionLabel,
  generateDisabledOptions,
  fetchAsync,
  defaultEmpty,
  ORSearch,
  inList,
  autoHeight,
  url,
  disableFetch,
  customPagination,
  labelAddOns,
  validateOnSelect,
  onSelectValidateData,
  selectAll,
  clearable,
  SWRRefetchOnFocus,
  disableViewPage = false,
  selectFirstOption = false,
  ...rest
}) => {
  const { isViewPage: isInViewPage, isViewProcessingPage } = usePageType()
  const isViewPage = isInViewPage && !disableViewPage

  const selectRef = useRef(null)

  // Local States
  const [selectOptions, setSelectOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputvalue] = useState("")

  const inputLabel =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const fetcher = async (url, params) => {
    const isURLHasNum =
      compact(url.split("/").map((item) => +item)).length > 0 ? true : false
    let additionalParams = isURLHasNum
      ? {}
      : disableSorting
      ? { paginate: customPagination ? customPagination : 25 }
      : {
          paginate: customPagination ? customPagination : 25,
          sort_type: "asc",
          sort_by: mappingLabel,
        }

    const res = await generalRequest({
      url,
      ...additionalParams,
      ...params,
    })

    if (res) {
      if (res.items) {
        const itemsOptions = inputField?.predefined_value
          ? res.items.children
          : res.items
        // select the first option by default
        if (selectFirstOption && itemsOptions?.length > 0) {
          customOnChange
            ? customOnChange(itemsOptions[0], { action: "select" })
            : handleChange(itemsOptions[0], { action: "select" })
        }

        return itemsOptions
      }
      if (res.item) {
        const itemsOptions = inputField?.predefined_value
          ? res.item.children
          : res.item
        // select the first option by default
        if (selectFirstOption && itemsOptions?.length > 0) {
          customOnChange
            ? customOnChange(itemsOptions[0], { action: "select" })
            : handleChange(itemsOptions[0], { action: "select" })
        }
        return itemsOptions
      }
    }
  }

  const params = defaults(urlAddOns, dynamicQuery)

  const {
    isLoading: loading,
    error: err,
    data,
  } = useSWR(
    () => (url && !disableFetch ? [url, params] : null),
    ([url, params]) => fetcher(url, params),
    {
      revalidateOnFocus: SWRRefetchOnFocus ? SWRRefetchOnFocus : false,
    }
  )

  // handle input change event
  const handleInputChange = (value) => {
    setInputvalue(value)
  }

  // handle change
  const handleChange = (selectedValue, { action }) => {
    if (validateOnSelect && onSelectValidateData) {
      onSelectValidateData(name, selectedValue)
    }

    if (action === "clear" && onClear) {
      onClear()
    }
    if (multiple) {
      onChange(`${name}`, selectedValue.length > 0 ? selectedValue : [])
      if (onSelectData) {
        onSelectData(selectedValue)
      }
    } else {
      onChange(`${name}`, selectedValue ? selectedValue[mappingValue] : "")
      if (onSelectData) {
        onSelectData(selectedValue)
      }
    }
  }

  // handle blur
  const handleBlur = () => {
    if (onBlur) {
      onBlur(`${name}`, true)
    }
  }

  // load options using API call
  const loadOptions = async (inputValue) => {
    const newURLAddons = omit(urlAddOns, ["search_keys", "search_values"])
    const newDynamicURLAddons = omit(dynamicQuery, [
      "search_keys",
      "search_values",
    ])
    const propSearchKeys = has(urlAddOns, "search_keys")
      ? pick(urlAddOns, ["search_keys"])
      : has(dynamicQuery, "search_keys")
      ? pick(dynamicQuery, ["search_keys"])
      : ""
    const propSearchValues = has(urlAddOns, "search_values")
      ? pick(urlAddOns, ["search_values"])
      : has(dynamicQuery, "search_values")
      ? pick(dynamicQuery, ["search_values"])
      : ""

    const newSearchKeys = searchKey
      ? [...Object.values(propSearchKeys), searchKey].join(",")
      : [...Object.values(propSearchKeys)].join(",")
    const newSearchValues = searchKey
      ? [...Object.values(propSearchValues), inputValue].join(",")
      : [...Object.values(propSearchValues)].join(",")

    let customSearch = {}
    searchParam
      ? set(customSearch, searchParam, inputValue)
      : (customSearch = {})
    // don’t fire the endpoint if the input field is below 3 charac
    // if (inputValue.length < 3) return

    let basicParams = {
      // sort_type: "asc",
      // sort_by: mappingLabel,
      //   per_page: 1000,
      ...newDynamicURLAddons,
      ...newURLAddons,
      sort_type: !disableSorting ? "desc" : "",
      sort_by: !disableSorting ? mappingValue : "",

      search_keys: newSearchKeys,
      search_values: newSearchValues,
      // search_keys: searchKey ? searchKey : "",
      // search_values: searchKey ? (inputValue ? inputValue : "null") : "",
      ...customSearch,
    }

    basicParams = ORSearch
      ? { ...basicParams, search_value: inputValue }
      : basicParams
    let res
    try {
      setIsLoading(true)
      if (recordId) {
        res = await getOptionsData(recordId)
      } else {
        res = await getOptionsData(basicParams)
      }
      if (res) {
        if (res.items) {
          return res.items
        }
        if (res.item) {
          return res.item
        }
      }
    } catch (error) {
      console.log(error)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (url && data) {
      setSelectOptions(data)
      setIsLoading(loading)
    }
  }, [data, url])

  // clear
  useEffect(() => {
    if (!propValue && clearable) {
      console.log("clearing")
      selectRef?.current?.clearValue()
      console.log("cleared")
    }
  }, [propValue, clearable])

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <div className="d-flex align-items-start gap-2">
          <Label
            htmlFor={id}
            className={`text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${labelClass ? labelClass : ""}`}
            // data-toggle="tooltip"
            // title={placeholder ? placeholder : ""}
            id={`tooltip-${id}`}
          >
            {inputLabel}
          </Label>
          {placeholder && (
            <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
              {placeholder}
            </UncontrolledTooltip>
          )}
          {!isViewPage && !isViewProcessingPage && (
            <Fragment>
              {/* Select All Button */}
              {selectAll ? (
                <button
                  className="btn btn-transparent p-0 border-0 m-0 position-relative d-inline-block"
                  style={{ top: "-2px" }}
                  aria-label="Select All options"
                  type="button"
                  data-toggle="tooltip"
                  title="Select All Options"
                  // onClick={() => handleChange(selectOptions, { action: "select" })}
                  onClick={() =>
                    handleChange(
                      generateDisabledOptions
                        ? selectOptions.filter(
                            (item) => !generateDisabledOptions(item)
                          )
                        : selectOptions,
                      { action: "select" }
                    )
                  }
                >
                  <i className="mdi mdi-playlist-check font-size-18"></i>
                </button>
              ) : null}
              <Fragment>{labelAddOns && labelAddOns}</Fragment>
            </Fragment>
          )}
        </div>
      )}

      <div className={horizontalLayout && inputClass}>
        {loading ? (
          <DropDownSkeleton />
        ) : (
          <AsyncSelect
            ref={selectRef}
            onInputChange={handleInputChange}
            cacheOptions
            defaultOptions={selectOptions}
            loadOptions={loadOptions}
            name={name}
            id={id}
            placeholder={placeholder}
            getOptionLabel={(e) =>
              generateCustomLabels ? generateCustomLabels(e) : e[mappingLabel]
            }
            getOptionValue={(e) => e[mappingValue]}
            // disable search based on porp or in view page
            isSearchable={
              disableSearch || isViewPage || isViewProcessingPage ? false : true
            }
            value={
              multiple
                ? propValue.map((item) =>
                    isItemObject(item)
                      ? item
                      : selectOptions.filter((option) =>
                          propValue.includes(option[mappingValue])
                        )
                  )
                : isItemObject(propValue)
                ? propValue
                : selectOptions.find(
                    (option) => option[mappingValue] === propValue
                  )
            }
            onChange={customOnChange ? customOnChange : handleChange}
            onBlur={handleBlur}
            // options={options ? options : selectOptions}
            {...rest}
            className={`select2-selection form-control p-0 ${
              invalid ? "border-danger" : ""
            }${inputClass ? inputClass : ""}${
              isViewPage || isViewProcessingPage ? "border-0" : ""
            }`}
            // overide the default style
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
              }),

              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({
                ...provided,
                // if this component in the list page
                // like column filter make the width auto else make it 100%
                width: inList ? "auto" : "100%",
                zIndex: 9999,
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                height: "100%",
              }),
            }}
            menuPosition={fixedMenu && "fixed"}
            // menuShouldBlockScroll={true}
            // menuPosition="fixed"
            // disable clear option in view page
            isClearable={!isViewPage && !isViewProcessingPage}
            // hide the menu,sperator and dropdown icon in view page
            components={
              isViewPage || isViewProcessingPage
                ? {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Menu: () => null,
                  }
                : {
                    IndicatorSeparator: () => null,
                  }
            }
            isLoading={isLoading ? true : false}
            isDisabled={
              isViewPage || isViewProcessingPage || readOnly ? true : false
            }
            // multiple selection
            isMulti={multiple}
            hideSelectedOptions={hideSelectedOptions}
            isOptionDisabled={(option) =>
              generateDisabledOptions && generateDisabledOptions(option)
            }
          />
        )}
        {/* Error Message */}
        {invalid && !isLoading && !isViewPage && !isViewProcessingPage ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>

      {/* Modal that show up when user want to select another option from select menu */}
      {isWarningModal && showPopupOnChange && (
        <DropdownChangeModal
          show={isWarningModal && showPopupOnChange}
          onCloseClick={() => {
            setIsWarningModal(false)
          }}
        />
      )}
    </div>
  )
}

export default AsyncSearchSelect
