import React, { Fragment, useEffect, useRef, useState } from "react"
import { Label } from "reactstrap"
import Select from "react-select"
import FormInputError from "./FormInputError"
import { apiErrorrHandler, generateToast, NotificationMessage } from "utils"
import DropDownSkeleton from "./DropDownSkeleton"
import { useLocation } from "react-router-dom"
import DropdownChangeModal from "../Modals/DropdownChangeModal"
import useSWR from "swr"
import { generalRequest } from "helpers/General/general_request"
import { compact, defaults } from "lodash"
import { usePageType } from "hooks"

const SearchSelect = ({
  id,
  name,
  style,
  value: propValue,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  options,
  onChange,
  customOnChange,
  disableSearch,
  onBlur,
  invalid,
  error,
  getOptionsData,
  onSelectData,
  showPopupOnChange,
  urlAddOns,
  dynamicQuery,
  mappingLabel,
  mappingValue,
  wrapperClassNames,
  horizontalLayout,
  fixedMenu,
  multiple,
  noLabel,
  generateCustomLabels,
  hideSelectedOptions,
  recordId,
  onClear,
  formatOptionLabel,
  generateDisabledOptions,
  disableSorting,
  readOnly,
  validateOnSelect,
  onSelectValidateData,
  generateFilteredOptions,
  notBlurAfterSelect,
  selectOnMount,
  fetchAsync,
  url,
  disableFetch,
  specificKey,
  clearable,
  SWRRefetchOnFocus,
  labelAddOns,
  showLoading,
  handleClickReload,
  disableViewPage = false,
  ...rest
}) => {
  const location = useLocation()
  const { pathname } = location
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2]

  const { isViewPage: isInViewPage } = usePageType()

  const isViewPage = isInViewPage && !disableViewPage

  const fetcher = async (url, params) => {
    const isURLHasNum =
      compact(url.split("/").map((item) => +item)).length > 0 ? true : false
    let additionalParams = isURLHasNum
      ? {}
      : disableSorting
      ? { paginate: 1000 }
      : {
          paginate: 1000,
          sort_type: "asc",
          sort_by: mappingLabel,
        }

    setIsLoading(true)
    const res = await generalRequest({
      url,
      ...additionalParams,
      ...params,
    })

    setIsLoading(false)
    if (res) {
      if (res.items) {
        const itemsOptions = inputField?.predefined_value
          ? res.items?.children
            ? res.items.children
            : res.items
          : res.items
        setSelectOptions(itemsOptions)
        return itemsOptions
      }
      if (res.item) {
        const itemOption = inputField?.predefined_value
          ? res.item.children
          : specificKey
          ? res.item[specificKey]
          : res.item

        setSelectOptions(itemOption)
        return itemOption
      }
    }
  }

  const params = defaults(urlAddOns, dynamicQuery)
  const {
    isLoading: loading,
    error: err,
    data,
  } = useSWR(
    () => (url && !disableFetch ? [url, params] : null),
    ([url, params]) => fetcher(url, params),
    {
      revalidateOnFocus: SWRRefetchOnFocus ? SWRRefetchOnFocus : false,
    }
  )

  // Local States
  const [selectOptions, setSelectOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [counter, setCounter] = useState(1)

  const selectRef = useRef(null)
  const inputLabel =
    inputField && inputField.label_name ? inputField.label_name : propLabel
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`

  const handleChange = (selectedValue, { action }) => {
    console.log("selected Value", selectedValue)
    console.log("selected Action", action)
    if (validateOnSelect && onSelectValidateData) {
      onSelectValidateData(name, selectedValue)
    }
    if (action === "clear" && onClear) {
      onClear()
    }
    if (multiple) {
      onChange(
        `${name}`,
        selectedValue.length > 0
          ? mappingValue
            ? selectedValue.map((item) => item[mappingValue])
            : selectedValue.map((item) => item.value)
          : ""
      )
      if (onSelectData) {
        onSelectData(selectedValue)
      }
    } else {
      onChange(
        `${name}`,
        selectedValue
          ? mappingValue
            ? selectedValue[mappingValue]
            : selectedValue.value
          : ""
      )
      if (onSelectData) {
        onSelectData(selectedValue)
        if (!notBlurAfterSelect) {
          selectRef.current.blur()
        }
      }
    }
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur(`${name}`, true)
    }
  }

  const getData = async () => {
    setIsLoading(true)
    let res
    try {
      if (recordId) {
        res = await getOptionsData(recordId)
      } else {
        res = await getOptionsData({
          sort_type: !disableSorting ? "asc" : "",
          sort_by: !disableSorting ? mappingLabel : "",
          per_page: 1000,
          ...urlAddOns,
          ...dynamicQuery,
        })
      }
      if (res) {
        if (res.items) {
          setSelectOptions(
            inputField?.predefined_value ? res.items.children : res.items
          )
        }
        if (res.item) {
          setSelectOptions(
            inputField?.predefined_value ? res.item.children : res.item
          )
        }
      } else {
        setSelectOptions([])
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  // for fetching on page load
  useEffect(() => {
    if (
      !fetchAsync &&
      getOptionsData &&
      (mappingLabel || generateCustomLabels) &&
      mappingValue &&
      !inputField?.predefined_value?.id &&
      !url
    ) {
      getData()
    }
  }, [
    JSON.stringify(dynamicQuery),
    recordId,
    fetchAsync,
    JSON.stringify(inputField),
    url,
  ])

  // for fetchiing async
  useEffect(() => {
    if (
      fetchAsync &&
      isOpen &&
      getOptionsData &&
      (mappingLabel || generateCustomLabels) &&
      mappingValue
    ) {
      getData()
    }
  }, [JSON.stringify(dynamicQuery), recordId, fetchAsync, isOpen])

  const showToastNotification = () => {
    generateToast(
      "warning",
      "There Are some data will be changing after this action!",
      "Warning"
    )
  }

  useEffect(() => {
    if (
      selectOnMount &&
      propValue &&
      selectOptions.length > 0 &&
      counter === 1
    ) {
      const value = selectOptions.find(
        (item) => item[mappingValue] === propValue
      )
      selectRef?.current?.selectOption(value)
      setCounter(0)
    }
  }, [selectOnMount, propValue, selectOptions?.length])

  useEffect(() => {
    if (url && data) {
      setSelectOptions(
        specificKey ? compact(data.map((item) => item[specificKey])) : data
      )
      setIsLoading(loading)
    }
  }, [data, url, specificKey])

  useEffect(() => {
    if (disableFetch) setSelectOptions([])
  }, [disableFetch])

  // clear
  useEffect(() => {
    if (!propValue && clearable) {
      console.log("clearing")
      selectRef?.current?.clearValue()
      console.log("cleared")
    }
  }, [propValue, clearable])

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <div className="d-flex align-items-start gap-2">
          <Fragment>
            <Label
              htmlFor={id}
              className={`text-capitalize  ${
                inputField?.is_required ? "required-label" : ""
              } ${labelClass ? labelClass : ""}`}
            >
              {inputLabel}
            </Label>
          </Fragment>

          {!isViewPage && (
            <Fragment>
              {/* Reload Button */}
              {labelClass.includes("reload-button") ? (
                <button
                  className="btn btn-transparent p-0 border-0 m-0  position-relative d-inline-block"
                  style={{ top: "-4px" }}
                  aria-label="Reload Options"
                  type="button"
                  data-toggle="tooltip"
                  title="Reload Options"
                  onClick={() =>
                    handleClickReload
                      ? handleClickReload()
                      : fetcher(url, params)
                  }
                >
                  <i className="mdi mdi-reload font-size-18"></i>
                </button>
              ) : null}

              {labelAddOns && labelAddOns}
            </Fragment>
          )}
        </div>
      )}

      <div className={horizontalLayout && inputClass}>
        {(isLoading || showLoading) && !fetchAsync ? (
          <DropDownSkeleton />
        ) : (
          <Select
            name={name}
            id={id}
            ref={selectRef}
            placeholder={placeholder}
            formatOptionLabel={
              generateCustomLabels
                ? (data) => generateCustomLabels(data)
                : (data) => (options ? data?.label : data[mappingLabel])
            }
            getOptionLabel={(e) => (options ? e.label : e[mappingLabel])}
            getOptionValue={(e) => (options ? e.value : e[mappingValue])}
            // disable search based on porp or in view page
            isSearchable={
              disableSearch || isViewPage || readOnly ? false : true
            }
            value={
              selectOptions.length > 0
                ? multiple
                  ? selectOptions.filter((option) =>
                      propValue.includes(option[mappingValue])
                    )
                  : selectOptions.find(
                      (option) => option[mappingValue] === propValue
                    )
                : options?.find((option) => option.value === propValue)
            }
            onChange={customOnChange ? customOnChange : handleChange}
            onBlur={handleBlur}
            // onMenuOpen={() => setIsWarningModal(true)}
            onMenuOpen={() => {
              if (showPopupOnChange && !isViewPage) {
                showToastNotification()
              }
              if (fetchAsync) {
                setIsOpen(!isOpen)
              }
            }}
            options={
              generateFilteredOptions
                ? generateFilteredOptions(selectOptions)
                : options
                ? options
                : selectOptions
            }
            {...rest}
            className={`select2-selection form-control p-0 ${
              invalid ? "border-danger" : ""
            }${inputClass ? inputClass : ""}${isViewPage ? "border-0" : ""} ${
              readOnly ? "bg-secondary bg-opacity-10" : ""
            }`}
            // overide the default style
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            menuPosition={fixedMenu && "fixed"}
            // menuShouldBlockScroll={true}
            // menuPosition="fixed"
            // disable clear option in view page
            isClearable={!isViewPage}
            // hide the menu,sperator and dropdown icon in view page
            components={
              isViewPage && {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Menu: () => null,
              }
            }
            isDisabled={readOnly}
            // multiple selection
            isMulti={multiple}
            isLoading={isLoading && fetchAsync ? true : false}
            hideSelectedOptions={hideSelectedOptions}
            isOptionDisabled={(option) =>
              generateDisabledOptions && generateDisabledOptions(option)
            }
          />
        )}
        {/* Error Message */}
        {invalid && !isLoading && !isViewPage ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>

      {/* Modal that show up when user want to select another option from select menu */}
      {isWarningModal && showPopupOnChange && (
        <DropdownChangeModal
          show={isWarningModal && showPopupOnChange}
          onCloseClick={() => {
            setIsWarningModal(false)
          }}
        />
      )}
    </div>
  )
}

export default SearchSelect
