/* Paths */
import { financePaths } from "common/data/routePaths"
import PaymentReceiptPrint from "pages/Finance/MemberReceipt/PaymentreceiptPrint/PaymentReceiptPrint"
import { lazy } from "react"
/*
Components
*/

const Invoice = lazy(() => import("pages/Finance/Invoice"))
const InvoiceForms = lazy(() => import("pages/Finance/Invoice/InvoiceForms"))

const PolicyPayment = lazy(() => import("pages/Finance/PolicyPayment"))
const PolicyPaymentForms = lazy(() =>
  import("pages/Finance/PolicyPayment/PolicyPaymentForms")
)
const CommissionPayment = lazy(() => import("pages/Finance/CommissionPayment"))
const CommissionPaymentForm = lazy(() =>
  import("pages/Finance/CommissionPayment/CommissionPaymentForm")
)
const PolicyPaymentPrint = lazy(() =>
  import("pages/Finance/PolicyPayment/PolicyPaymntPrint/PolicyPaymentPrint")
)
const InvoicePrint = lazy(() =>
  import("pages/Finance/Invoice/InvoicePrint/InvoicePrint")
)
const PaymentPrint = lazy(() =>
  import("pages/Finance/CommissionPayment/CommissionPaymentPrint/PaymentPrint")
)
const Collections = lazy(() => import("pages/Finance/Collections"))
const CollectionsTabs = lazy(() =>
  import("pages/Finance/Collections/CollectionsTabs")
)
const MemberReceipt = lazy(() => import("pages/Finance/MemberReceipt"))



// finance

// desctructure finance paths
const {
  invoice,
  policyPayment,
  commissionPayment,
  collections,
  memberReceipt,
} = financePaths

export const FinanceRoutes = [
  // invoice
  { path: invoice, component: Invoice },
  // add invoice
  { path: `${invoice}/add`, component: InvoiceForms },
  //edit invoice
  { path: `${invoice}/edit/:id`, component: InvoiceForms },
  //invoice print
  { path: `${invoice}/print/:id`, component: InvoicePrint },

  // Policy Payments
  { path: policyPayment, component: PolicyPayment },
  // add Receipt
  { path: `${policyPayment}/add`, component: PolicyPaymentForms },
  //edit Receipt
  { path: `${policyPayment}/edit/:id`, component: PolicyPaymentForms },
  //view Receipt
  { path: `${policyPayment}/view/:id`, component: PolicyPaymentForms },
  // Print Receipt
  { path: `${policyPayment}/print/:id`, component: PolicyPaymentPrint },

  //commission payment
  { path: commissionPayment, component: CommissionPayment },
  // add commission payment
  { path: `${commissionPayment}/add`, component: CommissionPaymentForm },
  // edit commission payment
  { path: `${commissionPayment}/edit/:id`, component: CommissionPaymentForm },
  // view commission payment
  { path: `${commissionPayment}/view/:id`, component: CommissionPaymentForm },
  // Print Receipt
  { path: `${commissionPayment}/print/:id`, component: PaymentPrint },

  //Collections
  { path: collections, component: Collections },

  // edit collections
  { path: `${collections}/edit/:beneficiaryId`, component: CollectionsTabs },

  // member receipts
  { path: memberReceipt, component: MemberReceipt },
  //print member receipts
  { path: `${memberReceipt}/print/:paymentReceiptsIds`, component: PaymentReceiptPrint },

 
]
