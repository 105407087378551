/*
parameters:
error object | Type : Object
*/

export function apiErrorrHandler(error) {
  let errorMessage
  const status = error && error.response && error.response.status

  if (status === 500) {
    errorMessage = "Something Went Wrong Please Try Again Later"
  } else if (status === 404) {
    errorMessage = "Sorry, the resource is not found"
  } else {
    errorMessage =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
  }

  return errorMessage
}
