// paths
import { stakeholderPaths } from "common/data/routePaths"

import { lazy } from "react"

// components
const Reinsurer = lazy(() => import("pages/Stakeholders/reinsurer"))
// health care providers
const HealthCareProvider = lazy(() =>
  import("pages/Stakeholders/HealthCareProvider")
)
const HealthCareProviderTabs = lazy(() =>
  import("pages/Stakeholders/HealthCareProvider/HealthCareProviderTabs")
)

// beneficiary list
const BeneficiaryList = lazy(() => import("pages/Stakeholders/BeneficiaryList"))

const UpdateTabs = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/UpdateTabs")
)
const AddBeneficiary = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/AddBeneficiary")
)
const BeneficiariesRenewal = lazy(() =>
  import("pages/Stakeholders/BeneficiariesRenewal")
)
const SubBeneficiariesRenewal = lazy(() =>
  import("pages/Stakeholders/SubBeneficiariesRenewal")
)

const SubBeneficiariesList = lazy(() =>
  import("pages/Stakeholders/SubBeneficiariesList")
)
const Broker = lazy(() => import("pages/Stakeholders/Broker"))
const BrokersForms = lazy(() =>
  import("pages/Stakeholders/Broker/BrokersForms")
)
const UploadProviderServices = lazy(() =>
  import(
    "pages/Stakeholders/HealthCareProvider/HealthCareProviderServices/UploadProviderServices"
  )
)
const BeneficiaryPrint = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/BeneficiaryPrint")
)
const ComputeAndValidate = lazy(() =>
  import("pages/Stakeholders/ComputeAndValidate")
)
const AddSubMemeber = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/AddSubMemeber")
)
const CustomersList = lazy(() => import("pages/Stakeholders/Customers"))
const CustomersForms = lazy(() =>
  import("pages/Stakeholders/Customers/CustomersForms")
)
// print installments
const InstallmentDivision = lazy(() =>
  import(
    "pages/Stakeholders/BeneficiaryList/UpdateTabs/InstallmentPdf/installmentDivision"
  )
)
const AdditionalEndoresmentPrint = lazy(() =>
  import(
    "pages/Stakeholders/BeneficiaryList/AdditionalEndoresment/AdditionalEndoresmentPrint"
  )
)
const BulkCardPrint = lazy(() =>
  import(
    "pages/Production/Policies/SecondTab/SubBeneficary/BulkCard/BulkCardPrint"
  )
)
const PrintCardPdf = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/PrintCardPdf")
)

// destructure the stakeholders paths object
const {
  reinsurer,
  healthcare_providers,
  beneficiaryList,
  broker,
  policyMember,
  customerList,
} = stakeholderPaths

const commonUpdateTabs = [
  `${beneficiaryList}/edit/:beneficiaryId`,
  `${beneficiaryList}/view/:beneficiaryId`,
  `${policyMember}/:policyId/edit/:beneficiaryId`,
  `${policyMember}/:policyId/view/:beneficiaryId`,
]
export const Stakeholders = [
  { path: reinsurer, component: Reinsurer },

  // health care providers
  {
    path: healthcare_providers,
    component: HealthCareProvider,
  },
  {
    path: `${healthcare_providers}/add`,
    component: HealthCareProviderTabs,
  },
  {
    path: `${healthcare_providers}/edit/:id`,
    component: HealthCareProviderTabs,
  },
  {
    path: `${healthcare_providers}/view/:id`,
    component: HealthCareProviderTabs,
  },
  {
    path: `${healthcare_providers}/:id/import`,
    component: UploadProviderServices,
  },

  // Beneficiary List
  { path: beneficiaryList, component: BeneficiaryList },

  //Add Beneficiary (with Policy)
  {
    path: `${beneficiaryList}/:policyId/add`,
    component: AddBeneficiary,
  },
  //Add Beneficiary (without Policy)
  {
    path: `${beneficiaryList}/add`,
    component: AddBeneficiary,
  },

  //Add Sub Beneficiary Beneficiary

  {
    path: `${beneficiaryList}/add/:parentId`,
    component: AddSubMemeber,
  },
  //Edit Beneficiary (member profile)
  {
    path: `${beneficiaryList}/edit/:beneficiaryId`,
    component: UpdateTabs,
  },
  //Edit Beneficiary ( policy member profile)
  {
    path: `${policyMember}/:policyId/edit/:beneficiaryId`,
    component: UpdateTabs,
  },
  //View Beneficiary (Member Profile)
  {
    path: `${beneficiaryList}/view/:beneficiaryId`,
    component: UpdateTabs,
  },
  //View Beneficiary ( policy Member Profile)
  {
    path: `${policyMember}/view/:policyId/:beneficiaryId`,
    component: UpdateTabs,
  },

  //Print Beneficiary
  {
    path: `${beneficiaryList}/print/:beneficiaryId/:policyId`,
    component: BeneficiaryPrint,
  },

  //Print Card
  {
    path: `${beneficiaryList}/print-card/:beneficiaryId`,
    component: PrintCardPdf,
  },
  //Print installment
  {
    path: `${beneficiaryList}/installment/print/:beneficiaryId/:policyId`,
    component: InstallmentDivision,
  },

  //Beneficiary Renewal
  { path: `${beneficiaryList}/renewal`, component: BeneficiariesRenewal },
  //SubBeneficiary Renewal
  //beneficiaryId:principle Id
  {
    path: `${beneficiaryList}/renewal/:policyId`,
    component: SubBeneficiariesRenewal,
  },

  //Policy Compute And Validate Beneficiaries
  {
    path: `${beneficiaryList}/compute/:policyId`,
    component: ComputeAndValidate,
  },
  {
    path: `${beneficiaryList}/bulk-card-print/:policyId`,
    component: BulkCardPrint,
  },

  //Sub Beneficiary List

  { path: "/sub-beneficiary-list/:parentId", component: SubBeneficiariesList },

  /* Borkers */

  //index
  { path: broker, component: Broker },
  //Add
  { path: `${broker}/add`, component: BrokersForms },
  //Edit
  { path: `${broker}/edit/:id`, component: BrokersForms },
  //Edit
  { path: `${broker}/view/:id`, component: BrokersForms },

  // Beneficiary List
  { path: customerList, component: CustomersList },
  { path: `${customerList}/add`, component: CustomersForms },
  { path: `${customerList}/edit/:id`, component: CustomersForms },
  { path: `${customerList}/view/:id`, component: CustomersForms },
]
