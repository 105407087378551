import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { userResetPassword } from "../../store/actions"

// import images
import profile from "assets/images/loginScreen.png"
import { NotificationMessage } from "utils"

const ResetPassword = (props) => {
  //meta title
  document.title = "Reset Password"

  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(props.location.search)
  const token = queryParams.get("token") || ""

  // states
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    newPasswordConfirmation: false,
  })
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      new_password: "",
      new_password_confirmation: "",
      token: token,
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required("Please Enter Your Password"),
      new_password_confirmation: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (values.new_password !== values.new_password_confirmation) {
        validation.setErrors({
          new_password_confirmation: "Passwords don't match",
        })
        return
      }
      try {
        await dispatch(userResetPassword(values, props.history))
        NotificationMessage("success", "Password Reset Successfully")
        props.history.push("/login")
      } catch (error) {
        NotificationMessage("error", "Failed to reset password")
      }
    },
  })

  const { loading } = useSelector((state) => ({
    //   savePassword:state.savePassword
    // error: state.Login.error,
    loading: state.Login.loading,
  }))
  return (
    <React.Fragment>
      {/* Home Icon On the Right Top Corner */}

      <div className="home-btn d-none d-sm-block">
        {/* Link To Home Page */}
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Aces TPA System.</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <Alert color="success" style={{ marginTop: "13px" }}>
                    Please Enter The New Password{" "}
                  </Alert>
                  <div className="p-2">
                    {/* Form */}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* Signing Error Message */}

                      {/* {error &&
                      validation.values.new_password !==
                        validation.values.new_password_confirmation ? (
                        <Alert color="danger">
                          {typeof error === "object" && error !== null
                            ? "Passwords do not match"
                            : error}
                        </Alert>
                      ) : null} */}
                      {validation.errors.new_password_confirmation && (
                        <Alert color="danger">
                          {validation.errors.new_password_confirmation}
                        </Alert>
                      )}

                      {/* Password Input  */}

                      <div className="mb-3 position-relative">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="new_password"
                          value={validation.values.new_password || ""}
                          type={showPassword.newPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.new_password &&
                            validation.errors.new_password
                              ? true
                              : false
                          }
                        />

                        {/* Show Password Icon */}

                        {validation.values.new_password.length > 0 ? (
                          <span
                            className="showPasswordIconContainer"
                            onClick={() =>
                              setShowPassword((prevState) => ({
                                ...prevState,
                                newPassword: !prevState.newPassword,
                              }))
                            }
                          >
                            <i
                              className={`mdi mdi-${
                                showPassword.newPassword
                                  ? "eye-off"
                                  : "eye-outline"
                              } font-size-18`}
                            ></i>
                          </span>
                        ) : null}

                        {/* Password Error Message */}

                        {validation.touched.new_password &&
                        validation.errors.new_password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.new_password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="form-label">
                          Password Confirmation
                        </Label>
                        <Input
                          name="new_password_confirmation"
                          value={
                            validation.values.new_password_confirmation || ""
                          }
                          type={
                            showPassword.newPasswordConfirmation
                              ? "text"
                              : "password"
                          }
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.new_password_confirmation &&
                            validation.errors.new_password_confirmation
                              ? true
                              : false
                          }
                        />

                        {/* Show Password Icon */}

                        {validation.values.new_password_confirmation.length >
                        0 ? (
                          <span
                            className="showPasswordIconContainer"
                            onClick={() =>
                              setShowPassword((prevState) => ({
                                ...prevState,
                                newPasswordConfirmation:
                                  !prevState.newPasswordConfirmation,
                              }))
                            }
                          >
                            <i
                              className={`mdi mdi-${
                                showPassword.newPasswordConfirmation
                                  ? "eye-off"
                                  : "eye-outline"
                              } font-size-18`}
                            ></i>
                          </span>
                        ) : null}

                        {/* Password Error Message */}

                        {validation.touched.new_password_confirmation &&
                        validation.errors.new_password_confirmation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {props.t("Save New Password")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t("Go back to")}{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    {props.t("Login")}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ResetPassword))

ResetPassword.propTypes = {
  history: PropTypes.object,
}
