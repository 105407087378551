import { request } from "../api_helper"

export const generalRequest = ({ url, ...rest }) =>
  request({
    url,
    method: "GET",
    params: {
      ...rest,
    },
  })
