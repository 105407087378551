export const isInputInvalid = (validation, fieldName) => {
  if (
    validation.touched &&
    validation.touched[fieldName] &&
    validation.errors &&
    validation.errors[fieldName]
  ) {
    return true
  } else {
    false
  }
}

export const isObjectInputInvalid = (
  validation,
  index,
  objectName,
  filedName
) => {
  if (
    validation.touched[objectName] &&
    validation.touched[objectName][index] &&
    validation.touched[objectName][index][filedName] &&
    validation.errors[objectName] &&
    validation.errors[objectName][index] &&
    validation.errors[objectName][index][filedName]
  ) {
    return true
  } else {
    false
  }
}
