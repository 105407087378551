import React from "react"
const FormTitle = ({ formType, title, subModule, classNames }) => {
  return (
    <h5 className={`mb-4 mt-2 text-capitalize ${classNames ? classNames : ""}`}>
      {formType ? formType : ""} {subModule ? subModule?.title : title}
    </h5>
  )
}

export default FormTitle
