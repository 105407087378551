import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { DateRangePicker } from "../Common/Forms"

function CardUser({ setDateRange, dateRange }) {
  const authUser = JSON.parse(localStorage.getItem("authUser"))

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row className="justify-content-between">
                <Col lg="4">
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome Back</p>
                        <h5 className="mb-1">{authUser?.name}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="4" className="d-none d-lg-block">
                  {/* <button className="btn btn-primary float-end">
                    <i className="bx bxs-cog align-middle me-1" /> Setting
                  </button> */}
                  <DateRangePicker
                    value={dateRange}
                    setDateRange={setDateRange}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
