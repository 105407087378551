import { request } from "../../api_helper"
import * as url from "../../url_helper"
/*
Policiess
*/

// add new Policy

// url
export const addPolicy = (data) =>
  request({ url: url.POLICIES, method: "POST", data })

// get all Policys with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllPolicies = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
  ...rest
}) =>
  request({
    url: url.POLICIES,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })

// get all Policy Endorsement with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllPolicyEndrosements = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...rest
  // view,
}) =>
  request({
    url: url.POLICIESENDORESMENT,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      // sort_by: sort_by ? sort_by : "id",
      // sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...rest,
      // view,
    },
  })

// show endorsement
//url/{id}
export const showEndorsement = (endorsementId) =>
  request({ url: `${url.POLICIESENDORESMENT}/${endorsementId}`, method: "GET" })

// show Policy
//url/{id}

export const showPolicy = (policyId) =>
  request({ url: `${url.POLICIES}/${policyId}`, method: "GET" })

// update Policy
//url/{id}

export const updatePolicy = (data) =>
  request({ url: `${url.POLICIES}/${data.id}`, method: "PUT", data })

// update Policy Settings
//url/{policyId}/settings

export const updatePolicySettings = (data) =>
  request({
    url: `${url.POLICIES}/${data.policy_id}/settings`,
    method: "PATCH",
    data,
  })

// delete Policy
//url/{id}
export const deletePolicy = (policyId) =>
  request({
    url: `${url.POLICIES}/${policyId}`,
    method: "DELETE",
  })

// activate Policy
//url/activate/{id}
export const activatePolicy = (policyId) =>
  request({
    url: `${url.POLICIES}/${policyId}/activate`,
    method: "POST",
  })

// deactivate Policy
//url/deactivate/{id}
export const deactivatePolicy = (policyId) =>
  request({
    url: `${url.POLICIES}/${policyId}/deactivate`,
    method: "POST",
  })

// renewal Policy
//url/{policyId}/renew
export const renewalPolicy = (policyId, data) =>
  request({
    url: `${url.POLICIES}/${policyId}/renew`,
    method: "POST",
    data,
  })

export const getPolicyPremiums = (policyId, params) =>
  request({
    url: `${url.POLICIES}/${policyId}/premiums`,
    method: "GET",
    params,
  })

// calculate renewal
// policyURL/policyId/calculate-renewal
export const calculateRenewal = (policyId, data) =>
  request({
    url: `${url.POLICIES}/${policyId}/calculate-renewal`,
    method: "POST",
    data,
  })
export const addPolicyTask = (id, data) =>
  request({
    url: `${url.POLICIES}/${id}/staff-tasks`,
    method: "POST",
    data,
  })

export const getAllPolicyTasks = (
  id,
  {
    page,
    per_page,
    sort_by,
    sort_type,
    search_keys,
    search_values,
    search_value,
    view,
    ...rest
  }
) =>
  request({
    url: `${url.POLICIES}/${id}/staff-tasks`,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      view,
      ...rest,
    },
  })
