/* Paths */
import { claimPaths } from "common/data/routePaths"
import { lazy } from "react"

/*
Components
*/
// claim form
const ClaimForm = lazy(() => import("pages/Claims/ClaimForm"))

// Add Claim
const AddClaimForm = lazy(() => import("pages/Claims/ClaimForm/ClaimForms"))

const { claimForm } = claimPaths
export const ClaimRoutes = [
  // Claim Form
  { path: claimForm, component: ClaimForm },

  // Add Claim
  { path: `${claimForm}/add`, component: AddClaimForm },
  // Updated Claim
  { path: `${claimForm}/edit/:claimId`, component: AddClaimForm },

  // view Claim
  {
    path: `${claimForm}/view/:claimId`,
    component: AddClaimForm,
  },
]
