/* Paths */
import { reportPaths } from "common/data/routePaths"
import { lazy } from "react"
/*
Components
*/

const DynamicReports = lazy(() => import("pages/Reports/DynamicReports"))
const BlackListedReports = lazy(() =>
  import("pages/Reports/BlackListedReports")
)
const DueToExpiryReport = lazy(() => import("pages/Reports/DueToExpiryReport"))
const AgentComissionReports = lazy(() =>
  import("pages/Reports/AgentComissionReports")
)
const NotRenewedReports = lazy(() => import("pages/Reports/NotRenewedReport"))
const AgentPoliciesReports = lazy(() =>
  import("pages/Reports/AgentPoliciesReports")
)

const {
  dynamicReport,
  blackListedReport,
  dueToExpirtyReport,
  agentComissionReport,
  notRenewedReports,
  agentPoliciesReports,
} = reportPaths

export const ReportsRoutes = [
  // dynamic reports
  { path: dynamicReport, component: DynamicReports },
  // blacklisted reports
  { path: blackListedReport, component: BlackListedReports },
  // due to expiry reports
  { path: dueToExpirtyReport, component: DueToExpiryReport },
  // Agent Comission Reports reports
  { path: agentComissionReport, component: AgentComissionReports },
  // not renewed reports
  { path: notRenewedReports, component: NotRenewedReports },
  // agent policies reports
  { path: agentPoliciesReports, component: AgentPoliciesReports },
]
