import { OPEN_MODAL, CLOSE_MODAL } from "./actionTypes"

const initialState = {
  isModal: false,
  message: "",
  title: "",
  confirmCallback: null,
  closeCallback: null,
  includeTwoButtons: false,
  confirmButtonTitle: "",
  closeButtonTitle: "",
}

const ToggleModal = (state = initialState, action) => {
  switch (action.type) {
    // Add New JobTitle
    case OPEN_MODAL:
      state = {
        ...state,
        isModal: true,
        message: action.payload.message ? action.payload.message : "",
        title: action.payload.title ? action.payload.title : "",
        includeTwoButtons: action.payload.twoButtons
          ? action.payload.twoButtons
          : "",
        confirmButtonTitle: action.payload.confirmButtonTitle
          ? action.payload.confirmButtonTitle
          : "",
        closeButtonTitle: action.payload.closeButtonTitle
          ? action.payload.closeButtonTitle
          : "",
        confirmCallback: action.payload.confirmCallback
          ? action.payload.confirmCallback
          : null,
        closeCallback: action.payload.closeCallback
          ? action.payload.closeCallback
          : null,
      }
      break
    case CLOSE_MODAL:
      state = {
        ...state,
        isModal: false,
        message: "",
        title: "",
        confirmButtonTitle: "",
        closeButtonTitle: "",
        includeTwoButtons: false,
        confirmCallback: null,
        closeCallback: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ToggleModal
