import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { claimDashboardID, dashboardsID } from "utils/modulesIds"
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle"
import { CardUser, FOBChart, KPIInfoCard } from "components/TP/dashboards"
import { showDashboard } from "helpers/General/dashboards_helper"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { useSelector } from "react-redux"
import { APIDateFormat, CompanyName } from "constants/TPA"
import moment from "moment"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import dayjs from "dayjs"
import { ModalSpinner } from "components/TP/Common/Forms"

const ClaimDashboard = (props) => {
  const [dashboardKPIs, setDashboardKPIs] = useState([])
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat),
    end_date: dayjs().format(APIDateFormat),
  })
  const [isLoading, setIsLoading] = useState(false)

  const { module, loading } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === claimDashboardID),
    loading: state.Sidebar?.loading,
  }))

  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-danger" , "--bs-secondary"]'

  const getDashboard = async (dashboardId, dates) => {
    setIsLoading(true)
    try {
      const response = await showDashboard(dashboardId, dates)
      setDashboardKPIs(response.item)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDashboard(claimDashboardID, {
      start_date: moment(dateRange.start_date).format(APIDateFormat),
      end_date: moment(dateRange.end_date).format(APIDateFormat),
    })
  }, [dateRange.start_date, dateRange.end_date])

  useEffect(() => {
    document.title = `${loading ? "loading..." : module?.title}`
  }, [loading])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {/* Welcome User Card */}
            <CardUser setDateRange={setDateRange} dateRange={dateRange} />
            {/* Loading State */}
            {isLoading ? <ModalSpinner color="primary" /> : null}
          </Row>

          {/* Info Cards form KPIs */}
          <Row className="mb-3">
            {!isLoading &&
              dashboardKPIs.filter((kpi) => !kpi.slug.includes("by-fob"))
                .length > 0 &&
              dashboardKPIs
                .filter((kpi) => !kpi.slug.includes("by-fob"))
                .map((kpi) => (
                  <Col md="3" key={kpi.slug}>
                    <KPIInfoCard
                      name={kpi.name}
                      count={Math.ceil(kpi.data)}
                      iconClass={
                        kpi.slug.includes("count")
                          ? "mdi mdi-account-cash-outline"
                          : "mdi mdi-account-box-multiple-outline"
                      }
                    />
                  </Col>
                ))}
          </Row>
          {/* Charts for FOB KPIs */}
          <Row>
            {!isLoading &&
              dashboardKPIs.filter((kpi) => kpi.slug.includes("by-fob"))
                .length > 0 &&
              dashboardKPIs
                .filter((kpi) => kpi.slug.includes("by-fob"))
                .map((kpi) => (
                  <FOBChart key={kpi.slug} KPI={kpi} dataColors={dataColors} />
                ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ClaimDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ClaimDashboard)
