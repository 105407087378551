import React from "react"
import { Modal } from "reactstrap"
import { withTranslation } from "react-i18next"

const BrokerSpecialNotes = ({ show, onCloseClick, t, broker }) => {
  return (
    <Modal
      size="md"
      isOpen={show}
      toggle={onCloseClick}
      // centered={notCentered ? false : true}
      backdrop="static"
    >
      <div className="modal-header">
        {/* Modal Header Title */}

        <h5 className="modal-title mt-0" id="myModalLabel">
          {t("Broker Special Notes")}
        </h5>
      </div>

      {/* Modal Body */}

      <div className="modal-body">
        {/* <h5>Insurer</h5> */}
        <h5>
          {broker?.name} {t("Special Notes")}
        </h5>
        <p>{broker?.special_notes || "N/A"}</p>
      </div>
      {/* Modal Footer */}
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={onCloseClick}
        >
          {t("Understood")}
        </button>
      </div>
    </Modal>
  )
}

export default withTranslation()(BrokerSpecialNotes)
