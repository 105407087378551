import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Form } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

// import images
import profile from "assets/images/loginScreen.png"
import logo from "assets/images/logo.svg"
import { CompanyName } from "constants/TPA"
import { FormControll } from "components/TP/Common/Forms"
import CustomButton from "components/TP/Common/General/CustomButton"
import { isInputInvalid } from "utils"

const Login = (props) => {
  //meta title

  useEffect(() => {
    document.title = `${CompanyName} | Login`
  }, [])

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history))
    },
  })

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    isSubmitting,
  } = validation

  const { error, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  return (
    <React.Fragment>
      {/* Home Icon On the Right Top Corner */}

      <div className="home-btn d-none d-sm-block">
        {/* Link To Home Page */}
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>
                          Sign in to continue to {CompanyName} Insurance System.
                        </p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    {/* Form */}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* Signing Error Message */}

                      {error ? (
                        <Alert color="danger">
                          {typeof error === "object" && error !== null
                            ? "Incorrect Username or Password"
                            : error}
                        </Alert>
                      ) : null}

                      {/* Email Input */}
                      <FormControll
                        id="485"
                        name="username"
                        type="text"
                        label="Username"
                        placeholder="Enter Username"
                        {...getFieldProps("username")}
                        invalid={isInputInvalid(validation, "username")}
                        error={errors.username}
                        wrapperClassNames="mb-3"
                      />

                      {/* Password Input  */}
                      <FormControll
                        id="485"
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter Password"
                        {...getFieldProps("password")}
                        invalid={isInputInvalid(validation, "password")}
                        error={errors.password}
                      />

                      <div className="mt-3 d-grid">
                        <CustomButton
                          title="Log In"
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                          isSaving={loading}
                        />
                      </div>

                      {/* Forget Password Link */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />

                          {props.t("Forgot your password?")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* Sign Up Now */}
                <p>
                  © {new Date().getFullYear()}{" "}
                  {props.t(`${CompanyName} Insurance System`)}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Login))

Login.propTypes = {
  history: PropTypes.object,
}
