import React, { useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { ConfigProvider, Switch } from "antd"

const ShowHideColumns = ({ showOptions, setShowOptions, fullColumns }) => {
  // state to handle dropdown opening and closing for Eye Icon

  const [isEyeOpen, setIsEyeOpen] = useState(false)

  const toggleColumn = column => {
    // check first wether the column is already in the filter array or not
    const isFound = showOptions.find(option => option === column.accessor)

    if (isFound) {
      // if it is already there remove it
      const filteredOptions = showOptions.filter(
        option => option !== column.accessor
      )
      setShowOptions(filteredOptions)
    } else {
      // if it is not there add it
      setShowOptions([...showOptions, column.accessor])
    }
  }
  return (
    <Dropdown isOpen={isEyeOpen} toggle={() => setIsEyeOpen(!isEyeOpen)}>
      {/* Show / hide DropDown Toggle */}

      <DropdownToggle color="primary">
        <i className="mdi mdi-eye font-size-14" />
      </DropdownToggle>

      {/* Show / hide Dropdown Menu Items */}
      <DropdownMenu className="dropdown-menu-end">
        {fullColumns &&
          fullColumns.map(column => (
            <DropdownItem
              text
              className="d-flex justify-content-between px-2 py-2 my-1"
              key={column.accessor}
              // to not close the menu after selecting
              toggle={false}
            >
              {column.Header}

              {/* changing the primary color of the ant 
                design switch to primary color of our application */}
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#556ee6",
                  },
                }}
              >
                {/* switch button from ant desing */}
                <Switch
                  size="small"
                  checked={
                    showOptions.find(option => option === column.accessor)
                      ? false
                      : true
                  }
                  onChange={() => toggleColumn(column)}
                />
              </ConfigProvider>
            </DropdownItem>
          ))}

        {/* Menu Divider */}
        <div className="dropdown-divider"></div>

        {/* Clear The Show/Hide Filters */}
        <DropdownItem onClick={() => setShowOptions([])}>Clear</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ShowHideColumns
