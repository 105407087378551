/*
    this hook is to scroll up to the top of 
    the  form window of a validation error happend 
*/

import { useEffect } from "react"
import { useSelector } from "react-redux"
import useModuleActions from "./useModuleActions"
import { useHistory, useLocation } from "react-router-dom"

// pass in an array of errors
const useSecureURL = () => {
  const history = useHistory()
  const location = useLocation()
  const pageType = location.pathname.split("/")[2]

  const { module } = useSelector(state => ({
    module: state.Module.actions,
  }))

  const {
    isBatchUploadPermission,
    isAddPermission,
    isUpdatePermission,
    isViewPermission,
  } = useModuleActions()
  useEffect(() => {
    if (module && pageType === "upload" && !isBatchUploadPermission) {
      history.replace("/")
    } else if (module && pageType === "add" && !isAddPermission) {
      history.replace("/")
    } else if (module && pageType === "edit" && !isUpdatePermission) {
      history.replace("/")
    } else if (module && pageType === "view" && !isViewPermission) {
      history.replace("/")
    }
  }, [module, pageType])
}

export default useSecureURL
