// paths
import { commonDataPaths } from "common/data/routePaths"
import { lazy } from "react"
// components
// Currencies
const Currencies = lazy(() => import("pages/CommonData/Currencies"))

// Event Manage
const Events = lazy(() => import("pages/CommonData/Events"))

// destructure commonDataPaths object

const { currencies, events } = commonDataPaths

export const CommonDataRoutes = [
  // currencies
  { path: currencies, component: Currencies },

  // Event Manager
  { path: events, component: Events },
]
