import PropTypes from "prop-types"
import React from "react"
import { Modal } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, isDeleting, t }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-header">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0" id="myModalLabel">
          {t("Delete")}
        </h5>

        {/* close button on the top right section */}
        <button
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Modal Body */}
      <div className="modal-body">
        <p className="text-muted font-size-16 mb-4 text-left">
          {t("Are you sure you want to delete this record?")}
        </p>
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-center mb-0">
          <button
            type="button"
            // className="btn btn-secondary"
            className="btn btn-light"
            onClick={onCloseClick}
          >
            {t("Close")}
          </button>
          <button
            type="button"
            // className="btn btn-danger"
            className="btn btn-primary"
            onClick={onDeleteClick}
            disabled={isDeleting}
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  isDeleting: PropTypes.bool,
}

export default withTranslation()(DeleteModal)
